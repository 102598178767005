// src/firebase.js
import { initializeApp, getApps, getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBasWRqnYRnHo0_rsaEiPUPWszzhtOI4R4",
    authDomain: "pos-api-demo.firebaseapp.com",
    projectId: "pos-api-demo",
    storageBucket: "pos-api-demo.appspot.com",
    messagingSenderId: "47241245525",
    appId: "1:47241245525:web:964671f1e84397e2b535dc",
    measurementId: "G-9427HWNCTY"
  };

  const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

  const auth = getAuth(app);
  const firestore = getFirestore(app);
  
export { auth, firestore };
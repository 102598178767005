import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBasWRqnYRnHo0_rsaEiPUPWszzhtOI4R4",
  authDomain: "pos-api-demo.firebaseapp.com",
  projectId: "pos-api-demo",
  storageBucket: "pos-api-demo.appspot.com",
  messagingSenderId: "47241245525",
  appId: "1:47241245525:web:01e9f3a2cfa996e2b535dc",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export {
  auth,
  db,
  createUserWithEmailAndPassword,
  collection,
  doc,
  setDoc,
  signInWithEmailAndPassword,
};

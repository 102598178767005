import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import App from "./App";
import { AuthProvider } from "./components/common/Login/AuthContext";
import { SideMenuContext } from "./components/common/SideMenuMobile/context/AuthContext";
import { LicentaProvider } from "./components/ai_pos/context/licentaContext";
import { SnackbarProvider } from "notistack";
import { CuiProvider } from "./components/ai_pos/context/cuiContext";
import { LicentaDataProvider } from "./components/ai_pos/context/licentaDataContext";
import { UserProvider } from "./components/ai_pos/context/userContext";
import { LicentaProviderGlobal } from "./components/ai_pos/context/licentaContextGlobal";
const container = document.getElementById("root");

const root = createRoot(container);

root.render(
  <BrowserRouter forceRefresh={true}>
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <AuthProvider>
        <UserProvider>
          <LicentaProviderGlobal>
            <LicentaDataProvider>
              <CuiProvider>
                <LicentaProvider>
                  <SideMenuContext>
                    <App />
                  </SideMenuContext>
                </LicentaProvider>
              </CuiProvider>
            </LicentaDataProvider>
          </LicentaProviderGlobal>
        </UserProvider>
      </AuthProvider>
    </SnackbarProvider>
  </BrowserRouter>
);

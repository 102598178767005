import React, { useState, useEffect } from "react";
import "./SideMenuMobile.css";
import { Link } from "react-router-dom";
import {
  ListItem,
  Collapse,
  Button,
  Modal,
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import ListIcon from "../../../images/list.png";
import { useAuth } from "../Login/AuthContext";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import { mainUrl } from "../../../utils/constants";
import PosIcon from "../../../images/pos.png";
import { useUserContext } from "../../ai_pos/context/userContext";

import TruckIcon from "../../../images/transNova.png";
import AgroIcon from "../../../images/agroNova.png";
import GestIcon from "../../../images/gestNova.png";
import ClientIcon from "../../../images/clientiNova.png";

const styleInv = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const SideMenu = () => {
  const [santiers, setSantiers] = useState([]);
  const [depozits, setDepozits] = useState([]);

  const { user } = useUserContext();

  const [santiersAgro, setSantiersAgro] = useState([]);
  const [depozitsAgro, setDepozitsAgro] = useState([]);

  const navigate = useNavigate();

  const { setIsAuthenticated, isAuthenticated } = useAuth();

  const material2 = {
    nume: "Alege Depozit",
  };

  const [selectedDepozit, setSelectedDepozit] = useState(material2);

  const handleDepozitChange = (event) => {
    setSelectedDepozit(event.target.value);
  };

  const [open2, setOpen2] = useState(false);

  const [nume, setNume] = useState();
  const [locatie, setLocatie] = useState();

  const [trigger, setTrigger] = useState();

  const sectiuniArray = [
    {
      nume: "AI_POS",
      icon: PosIcon,
      sectiuni: [
        {
          nume: "Dashboard",
          link: "ai_pos/dashboard",
        },
        // {
        //   nume: "Licenta",
        //   data: [
        //     { nume: "Licenta 990-POS", _id: 0 },
        //     { nume: "Licenta AI POS 2", _id: 1 },
        //     { nume: "Licenta AI POS 3", _id: 2 },
        //     { nume: "Licenta AI POS 4", _id: 3 },
        //     { nume: "Licenta AI POS 5", _id: 4 },
        //   ],
        //   dropdown: true,
        //   nick: "Licenta",
        // },
        {
          nume: "Licențe",
          dropdown: true,
          nick: "Licenta",
        },
        // {
        //   nume: "Inventar",
        //   link: "ai_pos/inventar",
        //   // dropdown: false,
        //   // nick: "DeviceManagement",
        // },
      ],
    },
  ];

  const styledSideMenu = {
    fontWeight: 400,
    fontSize: "17px",
    marginRight: "8px",
  };

  const styledSection = {
    fontWeight: 500,
    fontSize: "18px",
  };

  const [openSectionIndex, setOpenSectionIndex] = useState(null);
  const [selectedSubsectionIndex, setSelectedSubsectionIndex] = useState(null);

  const toggleSection = (index) => {
    setOpenSectionIndex((prevIndex) => (prevIndex === index ? null : index));
    // Close the selected subsection when a new section is clicked
    setSelectedSubsectionIndex(null);
  };

  const toggleSubsection = (index) => {
    setSelectedSubsectionIndex((prevIndex) =>
      prevIndex === index ? null : index
    );
  };

  const handleModalAdauga = () => {
    setOpen2(false);
    setSelectedDepozit("");
  };

  const handleLogOut = async () => {
    try {
      const response = await fetch("http://localhost:5050/api/users/logout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message);
      }
      localStorage.setItem("login", false);

      setIsAuthenticated(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }

    window.location.reload();
  };
  return (
    <>
      <div className="side-menu-container">
        <div className="side-menu">
          <br />

          {isAuthenticated &&
            sectiuniArray?.map((section, sectionIndex) => (
              <div key={sectionIndex}>
                <Collapse in={true}>
                  {section?.sectiuni?.map((subsection, subsectionIndex) => (
                    <div key={subsectionIndex} className="right-menu-item">
                      {subsection?.dropdown === true ? (
                        <ListItem
                          button
                          onClick={() => toggleSubsection(subsectionIndex)}
                        >
                          <span style={styledSideMenu}>{subsection?.nume}</span>
                          <svg
                            className="svgIconList"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <path
                                d="M20 7L4 7"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                              ></path>{" "}
                              <path
                                d="M15 12L4 12"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                              ></path>{" "}
                              <path
                                d="M9 17H4"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                              ></path>{" "}
                            </g>
                          </svg>{" "}
                        </ListItem>
                      ) : (
                        <Link
                          className="nav-link"
                          to={`${mainUrl}/ai_pos/dahsboard`}
                          onClick={() => setSelectedSubsectionIndex(null)}
                        >
                          {subsection?.nume}
                        </Link>
                      )}
                    </div>
                  ))}
                </Collapse>
              </div>
            ))}
          <div style={{ padding: "8px" }}>
            {isAuthenticated ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: 10,
                }}
              >
                {user && (
                  <span style={{ color: "white", marginRight: "10px" }}>
                    {user.name}
                  </span>
                )}
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleLogOut}
                >
                  {" "}
                  Deconectare
                </button>
              </div>
            ) : (
              <button
                type="button"
                className="btn btn-success"
                onClick={() => navigate("/login")}
                style={{ marginRight: "30px" }}
              >
                {" "}
                Log In
              </button>
            )}
          </div>
        </div>
        {/* Right-side menu */}
      </div>

      <Modal
        open={open2}
        onClose={handleModalAdauga}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div>
            <h2>
              Adauga{" "}
              {
                sectiuniArray[openSectionIndex]?.sectiuni[
                  selectedSubsectionIndex
                ]?.nick
              }
            </h2>
            {depozitsAgro.length === 0 &&
              sectiuniArray[openSectionIndex]?.sectiuni[selectedSubsectionIndex]
                ?.nick !== "Depozit" && (
                <span style={{ color: "red" }}>
                  {" "}
                  Intai trebuie creat un depozit de care sa apartina.
                </span>
              )}
            <br />
            <TextField
              type="text"
              placeholder={`Nume ${sectiuniArray[openSectionIndex]?.sectiuni[selectedSubsectionIndex]?.nick}`}
              onChange={(e) => setNume(e.target.value)}
            ></TextField>
            <br />
            <br />
            <TextField
              type="text"
              placeholder={`Locatie ${sectiuniArray[openSectionIndex]?.sectiuni[selectedSubsectionIndex]?.nick}`}
              onChange={(e) => setLocatie(e.target.value)}
            ></TextField>
            <br />
            <br />

            {sectiuniArray[openSectionIndex]?.sectiuni[selectedSubsectionIndex]
              ?.nick !== "Depozit" && (
              <>
                <InputLabel id="demo-simple-select-label">
                  Depozit{" "}
                  <Tooltip
                    title={`Depozitul de care apartine.`}
                    placement="right"
                  >
                    <InfoIcon />
                  </Tooltip>
                </InputLabel>
                <Select
                  value={selectedDepozit}
                  onChange={(e) => handleDepozitChange(e)}
                  style={{ width: "30%" }}
                >
                  {openSectionIndex === 2
                    ? depozitsAgro.map((material) => (
                        <MenuItem key={material._id} value={material._id}>
                          {material.nume} - {material.locatie}
                        </MenuItem>
                      ))
                    : depozits.map((material) => (
                        <MenuItem key={material._id} value={material._id}>
                          {material.nume} - {material.locatie}
                        </MenuItem>
                      ))}
                </Select>
                <br />
                <br />
              </>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default SideMenu;

import React, { useEffect } from "react";
import MySvgIcon2 from "../../images/AiPosV2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import AdsClickIcon from "@mui/icons-material/AdsClick";
import {
  auth,
  db,
  signInWithEmailAndPassword,
} from "../../utils/firebase/firebase";
import { useAuth } from "./Login/AuthContext";
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";

import aiPosLogo from '../../images/aiPosLogo.png'


const HomePage = () => {
  const { userData, setUserData } = useAuth();
  console.log("user", userData);

  const fetchUserCUIs = async () => {
    const userRef = doc(db, "users", userData.uid);
    try {
      console.log(userRef);
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        console.log("CUIs for user:", userData.CUIs);
        setUserData((prev) => ({
          ...prev,
          cui: userData.CUIs, // Assuming userData.CUIs is correctly populated
        }));
      } else {
        console.log("No user found with this ID:", userData.uid);
      }
    } catch (error) {
      console.error("Error fetching user CUIs:", error);
    }

    // const key = "ce469badb142f5d3866566c394260a14";
    // const cui = "42397489";

    // const response = await fetch("/api/cui_licenses", {
    //   method: "POST",
    //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
    //   body: new URLSearchParams({
    //     key: key,
    //     cui: cui,
    //   }).toString(),
    // });

    // const res = await response.json();
    // console.log(res);
  };

  useEffect(() => {
    if (userData.uid) {
      fetchUserCUIs();
    }
  }, []);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "50px",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <div style={{ width: "60%", fontSize: "20px" }}>
        <h1>Bine ați venit!</h1>
        <br></br>
        <img src={aiPosLogo} alt='aiPos' width="150px"/>


        {/* Prezentare generală */}
        <section style={{ paddingTop: "80px" }}>
      <div style={{ padding: '20px' }}>
        <h2 style={{ marginBottom: "15px" }}>Prezentare generală</h2>
        <p>
          Bine ați venit la <b>AIPOS Cloud</b>, soluția noastră cuprinzătoare de ERP proiectată pentru a ridica serviciile afacerii dvs. la noi înălțimi. Această aplicație este esențială pentru optimizarea operațiunilor afacerii dvs. prin oferirea unor funcționalități avansate și ușor de utilizat.
        </p>
        <br />
        <h3 style={{ marginBottom: "15px" }}>Beneficii</h3>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          <li style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
            <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginRight: '10px', marginTop: '4px' }} />
            <div>
              <strong>Salvare sigură a datelor în cloud:</strong> Datele dvs. sunt stocate în cloud, asigurându-vă că nu le veți pierde niciodată.
            </div>
          </li>
          <li style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
            <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginTop: '19px' }} />
            <div style={{marginRight: "8px", marginTop: "14px"}}>
              <strong>Gestionare eficientă a produselor:</strong> Puteți adăuga și șterge produse, le puteți organiza pe categorii și le puteți vizualiza cu ușurință.
            </div>
          </li>
          <li style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
            <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginTop: '4px', marginLeft: "2px" }} />
            <div style={{ marginRight: "35px"}}>
              <strong>Monitorizarea tranzacțiilor:</strong> Vizualizați toate tranzacțiile dvs. într-un singur loc, oferindu-vă o perspectivă clară asupra activităților financiare.
            </div>
          </li>
          <li style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
            <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginLeft: "3px", marginTop: '4px' }} />
            <div style={{ marginRight: "12px"}}>
              <strong>Sincronizare cu aplicația mobilă:</strong> Sistemul nostru se sincronizează cu aplicația mobilă, asigurând un mirroring perfect al datelor și funcționalităților între platforme.
            </div>
          </li>
          <li style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
            <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginLeft: "4px", marginTop: '4px' }} />
            <div style={{ marginLeft: "15px"}}>
              <strong>Gestionarea licențelor:</strong> Puteți vizualiza și manageria toate licențele dvs., adăuga licențe noi și gestiona detaliile acestora.
            </div>
          </li>
        </ul>
      </div>
    </section>
        {/* Module */}
        <section style={{ paddingTop: "40px" }}>
          <h2 style={{ marginBottom: "15px"}}>Module</h2>

          {/* Dashboard */}
          <div>
            <p>
              <b>Dashboard</b> - În Dashboard-ul din AIPOS Cloud, puteți vedea atât licențele care se află în cloud, cât și pe cele care nu se află încă. Acesta este locul unde puteți adăuga noi licențe și gestiona cele existente. Funcționalitățile intuitive vă permit să monitorizați și să gestionați toate licențele într-un mod eficient, oferindu-vă controlul și vizibilitatea necesare pentru a vă optimiza operațiunile.
            </p>
          </div>

          {/* Licente */}
          <div>
            <p>
              <b>Licențe</b> -Licente este doar un shortcut în sidebar care vă duce rapid la componenta de gestionare a licențelor. Aici, licențele sunt reprezentate prin numele lor și, la click, veți fi redirecționat către pagina specifică unde puteți modifica și gestiona detaliile fiecărei licențe.
            </p>
          </div>

         
        </section>

        {/* Cum funcționează */}
        <section style={{ paddingTop: "40px" }}>
          <h2 style={{ marginBottom: "15px"}}>Cum funcționează</h2>
          
          <p>
          <b>AIPOS Cloud</b> este conceput pentru a revoluționa modul în care gestionăm datele și operațiunile de afaceri, oferind o integrare perfectă între aplicația web și aplicația mobilă AIPOS. Această platformă avansată funcționează pe principiul sincronizării, asigurând un mirroring constant al datelor între cele două aplicații și utilizând o singură bază de date comună.
          </p>
          <p>
    În dashboard-ul aplicației AIPOS Cloud, utilizatorii pot vizualiza toate licențele active asociate cu codul unic de identificare (CUI) introdus la autentificare. De asemenea, platforma permite accesul și gestionarea licențelor asociate oricărui alt CUI existent pentru utilizatorul logat, oferind o flexibilitate și transparență de neegalat.
          </p>
          <p>
    Pentru licențele care nu sunt încă parte din ecosistemul AIPOS Cloud, există un buton de adăugare rapidă. Această funcționalitate facilitează includerea imediată a licențelor în cloud, asigurând că toate datele introduse și tranzacțiile sunt stocate într-o bază de date sigură și actualizată constant. Backup-ul continuu garantează integritatea și securitatea datelor, eliminând complet riscul pierderilor de informații.
          </p>
          <p>
    AIPOS Cloud transformă astfel modul în care companiile își gestionează informațiile, oferind o soluție robustă și eficientă pentru optimizarea fluxurilor de lucru și îmbunătățirea performanței operaționale.
  </p>
        </section>

        {/* Cum să începeți */}
        {/* <section style={{ paddingTop: "40px" }}>
          <h2>Cum să începeți</h2>
          <p>
            Pentru a vă personaliza experiența, vă invităm să solicitați o
            demonstrație personalizată. Echipa noastră vă va ghida prin
            funcționalitățile Smarty, evidențiind cum soluția noastră poate
            aduce beneficii specifice afacerii dvs.
          </p>
        </section> */}

        {/* De ce să alegeți Smarty */}
        {/* <section style={{ paddingTop: "40px" }}>
          <h2>De ce să alegeți Smarty?</h2>
          <p>
            Smarty se mândrește cu un istoric dovedit de succes. Alăturați-vă
            comunității noastre de clienți satisfăcuți și descoperiți cum Smarty
            poate aduce valoare și eficiență în operațiunile dvs. de zi cu zi.
          </p>
        </section> */}

        {/* Următorul pas */}
        {/* <section style={{ paddingTop: "40px" }}>
          <h2>Următorul pas cu Smarty!</h2>
          <p style={{ paddingBottom: "40px" }}>
            Indiferent dacă sunteți o afacere mică sau o întreprindere, Smarty
            este conceput pentru a vă împuternici. Explorați posibilitățile,
            descoperiți eficiența și ridicați-vă afacerea la noi înălțimi. Luați
            primul pas către îmbunătățirea operațiunilor dvs. de afaceri cu
            Smarty astăzi!
          </p> */}

          {/* <a
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "34px",
              fontWeight: 600,
            }}
            href="mailto:frank@blockarch.ro?subject=Demo%20smarty-erp.ro&body=Buna%20ziua,%0A%0AVa%20contactez%20in%20legatura%20cu%20un%20demo%20pentru%20smarty-erp.ro"
          >
            Contactați-ne pentru o demonstrație{" "}
            <AdsClickIcon fontSize="large" />
          </a>
        </section> */}
        <br />
        <br />

        <br />
      </div>
    </div>
  );
};

export default HomePage;

import { createContext, useContext, useEffect, useState } from "react";

const ContextLicentaGlobal = createContext();

export const LicentaProviderGlobal = ({ children }) => {
  const [licentaDataGlobal, setLicentaDataGlobal] = useState(() => {
    const storedUsedData = localStorage.getItem("licentaDataGlobal");
    return storedUsedData ? JSON.parse(storedUsedData) : false;
  });

  useEffect(() => {
    localStorage.setItem(
      "licentaDataGlobal",
      JSON.stringify(licentaDataGlobal)
    );
  }, [licentaDataGlobal]);

  return (
    <ContextLicentaGlobal.Provider
      value={{ licentaDataGlobal, setLicentaDataGlobal }}
    >
      {children}
    </ContextLicentaGlobal.Provider>
  );
};

export const useLicentaContextGlobal = () => {
  return useContext(ContextLicentaGlobal);
};

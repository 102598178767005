import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import { auth, db } from "../../utils/firebase/firebase";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { collection, doc, setDoc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";

const buttonStyle = {
  fontWeight: 700,
  marginTop: "16px",
};

function SignUpForm() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [CUI, setCUI] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== repeatPassword) {
      setError("Parolele nu se potrivesc.");
      return;
    }
    if (!CUI) {
      setError("CUI-ul este necesar.");
      return;
    }

    try {
      // Check if the CUI, email, or name already exists in the CUIS collection
      const cuiEmailDocRef = doc(db, "CUIS", "CUIsEmailsList");
      const cuiEmailDocSnap = await getDoc(cuiEmailDocRef);

      let data = {};
      if (cuiEmailDocSnap.exists()) {
        data = cuiEmailDocSnap.data();
      } else {
        // Initialize the arrays if the document does not exist
        data = {
          CUIs: [],
          Emails: [],
          // Names: []
        };
      }

      if (data.CUIs.includes(CUI)) {
        setError("CUI-ul deja există.");
        return;
      }
      if (data.Emails.includes(email)) {
        setError("Email-ul deja există.");
        return;
      }
      // if (data.Names.includes(username)) {
      //   setError("Numele deja există.");
      //   return;
      // }

      // Creating a user with the provided email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Saving the user in Firestore with the associated CUI
      await setDoc(doc(db, "users", user.uid), {
        name: username,
        email: user.email,
        CUIs: [CUI], // Initialize with the first CUI
      });

      // Creating a new collection named after the provided CUI with a "License" document
      const cuiRef = doc(collection(db, CUI), "License");
      await setDoc(cuiRef, {
        owner: user.uid,
        products: [], // Initialize products array
        transactions: [], // Initialize transactions array
        nomenclatoare: {}, // Initialize nomenclatoare object
        // Add other initial data as needed
      });

      // Add the new CUI, email, and name to the CUIS collection
      if (cuiEmailDocSnap.exists()) {
        await updateDoc(cuiEmailDocRef, {
          CUIs: arrayUnion(CUI),
          Emails: arrayUnion(email),
          Names: arrayUnion(username),
        });
      } else {
        await setDoc(cuiEmailDocRef, {
          CUIs: [CUI],
          Emails: [email],
          Names: [username],
        });
      }

      console.log("User registered with email:", email);
      navigate("/login");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", gap: 8 }}>
      <TextField value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Nume" />
      <TextField type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
      <TextField type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Parolă" />
      <TextField type="password" value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)} placeholder="Repetă Parola" />
      <TextField type="text" value={CUI} onChange={(e) => setCUI(e.target.value)} placeholder="CUI" />
      <Button variant="contained" color="primary" style={buttonStyle} type="submit">
        Înregistrează-te
      </Button>
      {error && <p>{error}</p>}
    </form>
  );
}

export default SignUpForm;

// import React, { useState } from "react";
// import { TextField, Button, Typography } from "@mui/material";
// import { auth, db } from "../../utils/firebase/firebase";
// import { useNavigate } from "react-router-dom";
// import { createUserWithEmailAndPassword } from "firebase/auth";
// import {
//   getFirestore,
//   collection,
//   doc,
//   setDoc,
//   getDoc,
// } from "firebase/firestore";

// const buttonStyle = {
//   fontWeight: 700,
//   marginTop: "16px",
// };

// function SignUpForm() {
//   const [username, setUsername] = useState("");
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [repeatPassword, setRepeatPassword] = useState("");
//   const [CUI, setCUI] = useState("");
//   const [error, setError] = useState("");
//   const navigate = useNavigate();

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     if (password !== repeatPassword) {
//       setError("Parolele nu se potrivesc.");
//       return;
//     }
//     if (!CUI) {
//       setError("CUI-ul este necesar.");
//       return;
//     }
//     try {
//       // Creating a user with the provided email and password
//       const userCredential = await createUserWithEmailAndPassword(
//         auth,
//         email,
//         password
//       );
//       const user = userCredential.user;

//       // Saving the user in Firestore with the associated CUI
//       await setDoc(doc(db, "users", user.uid), {
//         name: username,
//         email: user.email,
//         CUIs: [CUI], // Initialize with the first CUI
//       });

//       // Creating a new collection named after the provided CUI with a "License" document
//       const cuiRef = doc(collection(db, CUI), "License"); // This creates a reference to a document called 'License' inside the CUI collection
//       await setDoc(cuiRef, {
//         owner: user.uid,
//         products: [], // Initialize products array
//         transactions: [], // Initialize transactions array
//         nomenclatoare: {}, // Initialize nomenclatoare object
//         // Add other initial data as needed
//       });

//       console.log("User registered with email:", email);
//       navigate("/login");
//       // Optionally: redirect the user or perform other actions upon successful signup
//     } catch (error) {
//       setError(error.message);
//     }
//   };

//   return (
//     <form
//       onSubmit={handleSubmit}
//       style={{ display: "flex", flexDirection: "column", gap: 8 }}
//     >
//       <TextField
//         value={username}
//         onChange={(e) => setUsername(e.target.value)}
//         placeholder="Nume"
//       />
//       <TextField
//         type="email"
//         value={email}
//         onChange={(e) => setEmail(e.target.value)}
//         placeholder="Email"
//       />
//       <TextField
//         type="password"
//         value={password}
//         onChange={(e) => setPassword(e.target.value)}
//         placeholder="Parolă"
//       />
//       <TextField
//         type="password"
//         value={repeatPassword}
//         onChange={(e) => setRepeatPassword(e.target.value)}
//         placeholder="Repetă Parola"
//       />
//       <TextField
//         type="text"
//         value={CUI}
//         onChange={(e) => setCUI(e.target.value)}
//         placeholder="CUI"
//       />
//       <Button
//         variant="contained"
//         color="primary"
//         style={buttonStyle}
//         type="submit"
//       >
//         Înregistrează-te
//       </Button>
//       {error && <p>{error}</p>}
//     </form>
//   );
// }

// export default SignUpForm;

import React from "react";
import "./Spinner.css";
import SmartySpinner from "../../../images/smaSpinner.png";
import AiPosLogo from "../../../images/aiPosLogo.png"

const Spinner = () => {
  return (
    <div className="spinner-container">
      <img className="spinner" src={AiPosLogo} alt="Loading" />
      <br />
      <h4>Datele se incarca...</h4>
    </div>
  );
};

export default Spinner;

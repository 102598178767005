import React, { useState } from "react";
import { TextField, Button, Typography } from "@mui/material";
import md5 from "md5";
import { useAuth } from "./AuthContext";
import { useNavigate, Link } from "react-router-dom";
// import sma from "../../../images/smaTitle.png";
import MySvgIcon2 from "../../../images/AiPosV2";
import "./LoginPage.css";
import { auth, db } from "../../../utils/firebase/firebase";

import { signInWithEmailAndPassword } from "firebase/auth";

const headerStyle = {
  fontSize: "40px",
  fontWeight: 500,
};

const textFieldStyle = {
  marginBottom: "16px",
  width: "100%",
};

const buttonStyle = {
  fontWeight: 700,
  marginTop: "16px",
};

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { setIsAuthenticated, setUserData } = useAuth();
  const navigate = useNavigate();

  const currentDate = new Date();

  const sevenDaysLater = new Date(currentDate);
  const expireDate = sevenDaysLater.setDate(currentDate.getDate() + 7);

  // const futureDate = new Date(currentDate.getTime() + 10 * 1000);

  const handleEmailChange = (event) => {
    const trimmedEmail = event.target.value.trim();
    setEmail(trimmedEmail);
  };

  const validateEmail = (email) => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    return emailRegex.test(email);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLoginClick = async (e) => {
    e.preventDefault();

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      console.log("User logged in:", user.uid);

      const loginData = user;

      localStorage.setItem("expireDate", expireDate);
      localStorage.setItem("login", true);

      setUserData(loginData);
      setIsAuthenticated(true);
      navigate("/ai_pos/dashboard");
    } catch (error) {
      setError(error.message);
      console.log(error);
    }
  };

  return (
    <div
      style={{
        marginTop: "7%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div className="login-screen">
        <Typography style={headerStyle}>Bine ați venit la</Typography>
        <MySvgIcon2 />
        <br />
        <br />

        <Typography style={{ fontSize: "17px" }}>
          Suntem încântați să vă avem alături! Vă rugăm să vă conectați pentru a
          accesa platforma noastră!
        </Typography>
        <br />
        {error && <div className="error">{error}</div>}

        <form onSubmit={handleLoginClick}>
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            type="email"
            style={textFieldStyle}
            value={email}
            onChange={handleEmailChange}
            required
          />
          <TextField
            id="outlined-basic"
            label="Parolă"
            variant="outlined"
            type="password"
            style={textFieldStyle}
            value={password}
            onChange={handlePasswordChange}
            required
          />
           <div style={{ textAlign: "center", marginBottom: "16px" }}>
            <Link to="/forgot-password" style={{ textDecoration: "underlined", color: "blue" }}>
              Ați uitat parola?
            </Link>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={buttonStyle}
              onClick={handleLoginClick}
            >
              Autentificare
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={buttonStyle}
              onClick={() => navigate("/signup")}
            >
              Înregistrare
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { licenteArray } from "./aiPosContainer";
import { Paper } from "@mui/material";
import {
  Button,
  Modal,
  Box,
  TextField,
  Collapse,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  Select,
  MenuItem,
  InputLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import "./listaGestiune.css";
import { db } from "../../utils/firebase/firebase";
import { useLicentaContext } from "./context/licentaContext";
import { useLicentaDataContext } from "./context/licentaDataContext";

import { useSnackbar } from "notistack";

import {
  addDoc,
  collection,
  getDocs,
  getDoc,
  query,
  doc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Contract from "./inventar/raportInventarCentralPDF";
import { generateExcelFile } from "./raportTranzactii";
import { PDFDownloadLink } from "@react-pdf/renderer";

import moment from "moment";

const styleInv = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const LicentaView = () => {
  const { id } = useParams();
  const { licentaData, setLicentaData } = useLicentaContext();
  const [licenseData, setLicenseData] = useState({
    products: [],
    transactions: [],
    businessInfo: {},
  });

  const [openNomenclator, setOpenNomenclator] = useState(false);

  const [licenteNomenclatoare, setLicenteNomenclatoare] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const { licentaDataVal } = useLicentaDataContext();

  console.log("licDat", licentaDataVal);

  useEffect(() => {
    // Check if licentaData is available in context
    if (licentaData && licentaData.serial && licentaData.cui) {
      localStorage.setItem("licentaData", JSON.stringify(licentaData));
      readData(licentaData.cui, licentaData.serial);
    } else {
      // If not, try to load it from local storage
      const storedLicentaData = JSON.parse(localStorage.getItem("licentaData"));
      if (storedLicentaData) {
        setLicentaData(storedLicentaData);
        readData(storedLicentaData.cui, storedLicentaData.serial);
      }
    }
  }, [licentaData, setLicentaData, openNomenclator]);

  const readData = async (cui, licenseId) => {
    const docRef = doc(db, cui, licenseId);
    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setLicenseData(docSnap.data());
        setBusinessAddress(docSnap.data().businessInfo.address);
        setBusinessName(docSnap.data().businessInfo.name);
        setBusinessPhone(docSnap.data().businessInfo.phone);
        setLicenteNomenclatoare(docSnap.data().nomenclatoare);
        // console.log("Document data:", docSnap.data());
      } else {
        console.log("No such document!");
      }
    } catch (e) {
      console.error("Error fetching document:", e);
      alert(e);
    }
  };

  const iteme = licenseData?.products || [];
  const uniqueCategories = [...new Set(iteme.map((item) => item.category))];

  const [openMaterial, setOpenMaterial] = useState(false);
  const [categorieProdus, setCategorieProdus] = useState();
  const [umProdus, setUmProdus] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const [formEdit, setFormEdit] = useState({});

  const [openBon, setOpenBon] = useState(false);
  const [bonData, setBonData] = useState({});

  // testing for transactions
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [transactionPret, setTransactionPret] = useState("");
  const [transactionData, setTransactionData] = useState("");
  const [transactionDescriere, setTransactionDescriere] = useState("");

  const handleEditForm = (item) => {
    setFormEdit(item);
    setOpenEdit(true);
  };

  const updateForm = (value) => {
    setFormEdit((prev) => ({ ...prev, ...value }));
  };

  // const [nume, setNume] = useState();
  // const [um, setUm] = useState();
  // const [tva, setTva] = useState();
  // const [pret, setPret] = useState();
  const [nume, setNume] = useState("");
  const [um, setUm] = useState("");
  const [tva, setTva] = useState("");
  const [pret, setPret] = useState("");

  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [newUm, setNewUm] = useState("");
  const [openCategoryMobile, setOpenCategoryMobile] = useState(false);

  //  businessInfo
  const [openBusinessInfoModal, setOpenBusinessInfoModal] = useState(false); // State for business info modal
  const [businessAddress, setBusinessAddress] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessPhone, setBusinessPhone] = useState("");

  const [selectedNomenclator, setSelectedNomenclator] = useState("");
  const [nomenclatorId, setNomenclatorId] = useState("");
  const [nomenclatorEnabled, setNomenclatorEnabled] = useState("");
  const [nomenclatorValue, setNomenclatorValue] = useState("");
  const [nomenclatorLabel, setNomenclatorLabel] = useState("");

  // Filters for Vanzari
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [filteredTransactions, setFilteredTransactions] = useState([]);

  //
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editNomenclatorEnabled, setEditNomenclatorEnabled] = useState("");
  const [editNomenclatorValue, setEditNomenclatorValue] = useState("");
  const [editNomenclatorLabel, setEditNomenclatorLabel] = useState("");
  const [currentNomenclator, setCurrentNomenclator] = useState(null);

  const [indexAmef, setIndexAmef] = useState(0);
  const [currentType, setCurrentType] = useState("");

  const handleEditModalOpen = (nomenclator, type) => {
    setCurrentNomenclator(nomenclator);
    setCurrentType(type);
    setEditNomenclatorEnabled(nomenclator.enabled);
    setEditNomenclatorValue(nomenclator.value);
    setEditNomenclatorLabel(nomenclator.label);
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  useEffect(() => {
    setFilteredMaterials(iteme);
  }, [licenseData]);

  // useEffect for Filters:
  useEffect(() => {
    let transactions = licenseData?.transactions || [];
    if (startDate) {
      transactions = transactions.filter((transaction) =>
        moment(transaction.date).isSameOrAfter(startDate, "day")
      );
    }
    if (endDate) {
      transactions = transactions.filter((transaction) =>
        moment(transaction.date).isSameOrBefore(endDate, "day")
      );
    }
    if (paymentMethod) {
      transactions = transactions.filter(
        (transaction) => transaction.paymentType === paymentMethod
      );
    }
    setFilteredTransactions(transactions);
  }, [startDate, endDate, paymentMethod, licenseData]);

  // reset filters Button
  const resetFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setPaymentMethod("");
    setFilteredTransactions(licenseData?.transactions || []);
  };

  const handleAllCategory = (material) => {
    setFilteredMaterials(material);
    setOpenCategoryMobile(false);
  };
  const filterItem = (curcat, field) => {
    const newItem = iteme.filter((newVal) => newVal[field] === curcat);
    setFilteredMaterials(newItem);
    setOpenCategoryMobile(false);
  };

  const addProduct = async () => {
    const category =
      categorieProdus === "adauga" ? newCategory : categorieProdus;
    const ums = umProdus;
    const newProduct = {
      id: Date.now().toString(), // unique ID for the product
      name: nume,
      um: ums,
      price: pret,
      tva: tva,
      category: category,
    };

    try {
      const docRef = doc(db, licentaData.cui, licentaData.serial);
      await updateDoc(docRef, {
        products: arrayUnion(newProduct),
      });
      setLicenseData((prev) => ({
        ...prev,
        products: [...prev.products, newProduct],
      }));
      setOpenMaterial(false);
      setNume("");
      setUmProdus("");
      setPret("");
      setTva("");
      setCategorieProdus("");
      enqueueSnackbar(`Produsul ${nume} a fost adaugat.`, {
        variant: "success",
      });
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  // ADD NOMENCLATOR MODIFIED

  const addNomenclator = async (type) => {
    const newProduct = {
      id: nomenclatorId,
      enabled: nomenclatorEnabled ? "Da" : "Nu",
      value: nomenclatorValue,
      label: nomenclatorLabel,
      tva: tva,
    };

    try {
      const docRef = doc(db, licentaData.cui, licentaData.serial);
      await updateDoc(docRef, {
        [type]: arrayUnion(newProduct),
      });

      setLicenteNomenclatoare((prev) => ({
        ...prev,
        nomenclatoare: [...prev[selectedNomenclator], newProduct],
      }));

      handleModalNomenclator();

      enqueueSnackbar(`Nomenclatorul ${nomenclatorLabel} a fost adaugat.`, {
        variant: "success",
      });
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  // testing purpose transactions
  const addTransaction = async () => {
    const newTransaction = {
      id: transactionId,
      amount: transactionPret,
      date: transactionData,
      description: transactionDescriere,
    };

    try {
      const docRef = doc(db, licentaData.cui, licentaData.serial);
      await updateDoc(docRef, {
        transactions: arrayUnion(newTransaction),
      });
      setLicenseData((prev) => ({
        ...prev,
        transactions: [...prev.transactions, newTransaction],
      }));
      setOpenTransactionModal(false);
      setTransactionId("");
      setTransactionPret("");
      setTransactionData("");
      setTransactionDescriere("");
    } catch (error) {
      console.error("Error adding transaction:", error);
    }
  };

  const updateMaterial = async () => {
    const category =
      formEdit.category === "adauga" ? newCategory : formEdit.category;
    const updatedProducts = licenseData.products.map((product) =>
      product.id === formEdit.id ? { ...formEdit, category } : product
    );

    try {
      const docRef = doc(db, licentaData.cui, licentaData.serial);
      await updateDoc(docRef, {
        products: updatedProducts,
      });
      setLicenseData((prev) => ({
        ...prev,
        products: updatedProducts,
      }));
      setOpenEdit(false);
      enqueueSnackbar(`Produsul ${updatedProducts[0].name} a fost modificat.`, {
        variant: "success",
      });
    } catch (error) {
      console.error("Error updating material:", error);
    }
  };

  // add business info
  const updateBusinessInfo = async () => {
    const updatedBusinessInfo = {
      address: businessAddress,
      name: businessName,
      phone: businessPhone,
    };

    try {
      const docRef = doc(db, licentaData.cui, licentaData.serial);
      await updateDoc(docRef, {
        businessInfo: updatedBusinessInfo,
      });
      setLicenseData((prev) => ({
        ...prev,
        businessInfo: updatedBusinessInfo,
      }));
      setOpenBusinessInfoModal(false);
    } catch (error) {
      console.error("Error updating business info:", error);
    }
  };

  const deleteProduct = async (productId) => {
    const productToDelete = licenseData.products.find(
      (product) => product.id === productId
    );

    if (!productToDelete) {
      console.error("Product not found!");
      return;
    }

    const updatedProducts = licenseData.products.filter(
      (product) => product.id !== productId
    );

    try {
      const docRef = doc(db, licentaData.cui, licentaData.serial);
      await updateDoc(docRef, {
        products: updatedProducts,
      });
      enqueueSnackbar(`Produsul ${productToDelete.name} a fost șters.`, {
        variant: "error",
      });
      setLicenseData((prev) => ({
        ...prev,
        products: updatedProducts,
      }));
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const handleModalMaterialClose = () => {
    setOpenMaterial(false);
    setNewCategory("");
    setCategorieProdus("");
  };

  const handleModalTransactionClose = () => {
    setOpenTransactionModal(false);
    setTransactionId("");
    setTransactionPret("");
    setTransactionData("");
    setTransactionDescriere("");
  };

  const handleModalNomenclator = () => {
    setOpenNomenclator(false);
    setNomenclatorEnabled("");
    setNomenclatorValue("");
    setNomenclatorLabel("");
    setNomenclatorId("");
  };

  const handleNomenclatorClick = (type) => {
    setSelectedNomenclator(type);
    setOpenNomenclator(true);
  };

  const handleModalBusinessInfoClose = () => {
    setOpenBusinessInfoModal(false);
    setBusinessAddress("");
    setBusinessName("");
    setBusinessPhone("");
  };

  const handleBonClick = (item) => {
    setBonData(item);
    console.log("bon", item);
    setOpenBon(true);
  };

  console.log(licenseData);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // delete nomenclator function
  const deleteNomenclatoare = async (nomenclatorId, type) => {
    const nomenclatorToDelete = licenseData.nomenclatoare[type].find(
      (nomenclator) => nomenclator.id === nomenclatorId
    );

    if (!nomenclatorToDelete) {
      console.error("Nomenclator not found!");
      return;
    }

    const updatedNomenclatoare = {
      ...licenseData.nomenclatoare,
      [type]: licenseData.nomenclatoare[type].filter(
        (nomenclator) => nomenclator.id !== nomenclatorId
      ),
    };

    try {
      const docRef = doc(db, licentaData.cui, licentaData.serial);
      await updateDoc(docRef, {
        nomenclatoare: updatedNomenclatoare,
      });
      enqueueSnackbar(
        `Nomenclatorul ${nomenclatorToDelete.label} a fost șters.`,
        {
          variant: "error",
        }
      );
      setLicenseData((prev) => ({
        ...prev,
        nomenclatoare: updatedNomenclatoare,
      }));
    } catch (error) {
      console.error("Error deleting nomenclator:", error);
    }
  };

  // edit nomenclator Button
  const editNomenclator = async (type, nomenclator) => {
    const updatedNomenclator = {
      ...nomenclator,
      enabled: editNomenclatorEnabled,
      value: editNomenclatorValue,
      label: editNomenclatorLabel,
    };

    try {
      const docRef = doc(db, licentaData.cui, licentaData.serial);
      const docSnap = await getDoc(docRef);
      const nomenclatoare = docSnap.data()?.nomenclatoare || {};

      const updatedNomenclatoare = {
        ...nomenclatoare,
        [type]: nomenclatoare[type].map((item) =>
          item.id === nomenclator.id ? updatedNomenclator : item
        ),
      };

      await updateDoc(docRef, {
        nomenclatoare: updatedNomenclatoare,
      });

      // Update local state
      setLicenteNomenclatoare((prev) => ({
        ...prev,
        nomenclatoare: {
          ...prev.nomenclatoare,
          [type]: updatedNomenclatoare[type],
        },
      }));

      enqueueSnackbar(`Nomenclatorul ${nomenclator.label} a fost actualizat.`, {
        variant: "success",
      });

      // Immediately update the local state for UI
      licenseData.nomenclatoare[type] = updatedNomenclatoare[type];
    } catch (error) {
      console.error("Error updating nomenclator:", error);
    }
  };

  console.log("licentaData", licentaData);

  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridGap: "20px",
          width: "100%",
        }}
      >
        <div>
          <h3>Licența: {licentaData?.serial}</h3>
          <span>CUI: {licentaData?.cui}</span>
        </div>
      </div>

      <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: "12px" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Produse" {...a11yProps(0)} />
          <Tab label="Nomenclatoare" {...a11yProps(1)} />
          <Tab label="Vanzari" {...a11yProps(2)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <Paper elevation={3} style={{ padding: 12 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h2>Produse:</h2>
            <Button
              variant="contained"
              onClick={() => setOpenMaterial(true)}
              style={{ background: "#00054e" }}
            >
              Adaugă produs
            </Button>
          </div>
          <div className="desktop-grid-agro">
            <div className="desktop-agro">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span style={{ fontWeight: 500, fontSize: "18px" }}>
                  Categorii
                </span>
              </div>
              <div style={{ marginTop: "8px" }}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => setFilteredMaterials(iteme)}
                  style={{ background: "#00054e" }}
                >
                  Toate
                </Button>
              </div>
              {uniqueCategories.map((category) => (
                <div style={{ marginTop: "8px" }}>
                  <Button
                    key={category}
                    variant="contained"
                    style={{ background: "#00054e" }}
                    fullWidth
                    onClick={() => filterItem(category, "category")}
                  >
                    {category}
                  </Button>
                </div>
              ))}
            </div>
            <div className="mobile-agro">
              <Button
                variant="contained"
                onClick={() => setOpenCategoryMobile(!openCategoryMobile)}
                style={{ background: "#00054e" }}
              >
                Categorii
              </Button>
              <Collapse in={openCategoryMobile}>
                <div
                  style={{
                    borderRight: "1px solid #ccc",
                    padding: "12px",
                    borderRadius: "4px",
                    marginRight: "4px",
                  }}
                >
                  <div style={{ marginTop: "8px" }}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => handleAllCategory(iteme)}
                      style={{ background: "#00054e" }}
                    >
                      Toate
                    </Button>
                  </div>
                  {uniqueCategories.map((category) => (
                    <div style={{ marginTop: "8px" }}>
                      <Button
                        key={category}
                        variant="contained"
                        fullWidth
                        onClick={() => filterItem(category, "category")}
                      >
                        {category}
                      </Button>
                    </div>
                  ))}
                </div>
              </Collapse>
            </div>
            <div>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th style={{ paddingRight: "30px", paddingLeft: "30px" }}>
                      Nume
                    </th>
                    <th style={{ paddingRight: "30px" }}>Categorie</th>
                    <th style={{ paddingRight: "30px" }}>UM</th>
                    <th style={{ paddingRight: "30px" }}>TVA</th>
                    <th style={{ paddingRight: "30px" }}>Preț</th>
                    <th style={{ paddingRight: "30px" }}>SGR</th>

                    <th style={{ paddingRight: "30px" }}>Acțiune</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredMaterials.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.name}</td>
                      <td>{item?.category}</td>
                      <td>{item?.um}</td>
                      <td>{item?.tva}</td>
                      <td>{item?.price}</td>
                      <td>{item?.sgr}</td>
                      <td>
                        {/* <Button
                        variant="contained"
                        onClick={() => handleEditForm(item)}
                        style={{ background: "#00054e" }}
                      >
                        Edit
                      </Button> */}
                        <Button
                          variant="contained"
                          onClick={() => deleteProduct(item.id)}
                          style={{ marginLeft: "10px", background: "#FF6000" }}
                        >
                          Șterge
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Paper>{" "}
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "30px" }}>
          <div style={{ flex: "1 1 calc(50% - 20px)", marginBottom: "20px" }}>
            <Paper elevation={3} style={{ padding: 12 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h2>UMS:</h2>
                <Button
                  variant="contained"
                  onClick={() => handleNomenclatorClick("ums")}
                  style={{ background: "#00054e" }}
                >
                  Adaugă ums
                </Button>
              </div>
              <div className="desktop-grid-agro">
                <div>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th
                          style={{ paddingRight: "30px", paddingLeft: "30px" }}
                        >
                          Activat
                        </th>
                        <th style={{ paddingRight: "30px" }}>Label</th>
                        <th style={{ paddingRight: "30px" }}>Valoare</th>

                        <th style={{ paddingRight: "30px" }}>Acțiune</th>
                      </tr>
                    </thead>
                    <tbody>
                      {licenseData?.nomenclatoare?.ums?.map((item, index) => (
                        <tr key={index}>
                          <td>{item?.enabled}</td>
                          <td>{item?.label}</td>
                          <td>{item?.value}</td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "5px",
                                paddingRight: "10px",
                              }}
                            >
                              <Button
                                variant="contained"
                                onClick={() => handleEditModalOpen(item, "ums")}
                                style={{
                                  marginLeft: "10px",
                                  background: "#00054e",
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                variant="contained"
                                onClick={() =>
                                  deleteNomenclatoare(item.id, "ums")
                                }
                                style={{
                                  marginLeft: "10px",
                                  background: "#FF6000",
                                }}
                              >
                                Șterge
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Paper>
          </div>
          {/* <br /> */}
          <div style={{ flex: "1 1 calc(50% - 20px)", marginBottom: "20px" }}>
            <Paper elevation={3} style={{ padding: 12 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h2>TVA:</h2>
                <Button
                  variant="contained"
                  onClick={() => handleNomenclatorClick("tvas")}
                  style={{ background: "#00054e" }}
                >
                  Adaugă tva
                </Button>
              </div>
              <div className="desktop-grid-agro">
                <div>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th
                          style={{ paddingRight: "30px", paddingLeft: "30px" }}
                        >
                          Activat
                        </th>
                        <th style={{ paddingRight: "30px" }}>Label</th>
                        <th style={{ paddingRight: "30px" }}>Valoare</th>

                        <th style={{ paddingRight: "30px" }}>Acțiune</th>
                      </tr>
                    </thead>
                    <tbody>
                      {licenseData?.nomenclatoare?.tvas?.map((item, index) => (
                        <tr key={index}>
                          <td>{item?.enabled}</td>
                          <td>{item?.label}</td>
                          <td>{item?.value}</td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "5px",
                                paddingRight: "10px",
                              }}
                            >
                              <Button
                                variant="contained"
                                onClick={() =>
                                  handleEditModalOpen(item, "tvas")
                                }
                                style={{
                                  marginLeft: "10px",
                                  background: "#00054e",
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                variant="contained"
                                onClick={() =>
                                  deleteNomenclatoare(item.id, "tvas")
                                }
                                style={{
                                  marginLeft: "10px",
                                  background: "#FF6000",
                                }}
                              >
                                Șterge
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Paper>
          </div>
          {/* <br /> */}
          <div style={{ flex: "1 1 calc(50% - 20px)", marginBottom: "20px" }}>
            <Paper elevation={3} style={{ padding: 12 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h2>SGR:</h2>
                <Button
                  variant="contained"
                  onClick={() => handleNomenclatorClick("sgrs")}
                  style={{ background: "#00054e" }}
                >
                  Adaugă sgr
                </Button>
              </div>
              <div className="desktop-grid-agro">
                <div>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th
                          style={{ paddingRight: "30px", paddingLeft: "30px" }}
                        >
                          Activat
                        </th>
                        <th style={{ paddingRight: "30px" }}>Label</th>
                        <th style={{ paddingRight: "30px" }}>Valoare</th>

                        <th style={{ paddingRight: "30px" }}>Acțiune</th>
                      </tr>
                    </thead>
                    <tbody>
                      {licenseData?.nomenclatoare?.sgrs?.map((item, index) => (
                        <tr key={index}>
                          <td>{item?.enabled}</td>
                          <td>{item?.label}</td>
                          <td>{item?.value}</td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "5px",
                                paddingRight: "10px",
                              }}
                            >
                              <Button
                                variant="contained"
                                onClick={() =>
                                  handleEditModalOpen(item, "sgrs")
                                }
                                style={{
                                  marginLeft: "10px",
                                  background: "#00054e",
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                variant="contained"
                                onClick={() =>
                                  deleteNomenclatoare(item.id, "sgrs")
                                }
                                style={{
                                  marginLeft: "10px",
                                  background: "#FF6000",
                                }}
                              >
                                Șterge
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Paper>
          </div>
          {/* <br /> */}
          <div style={{ flex: "1 1 calc(50% - 20px)", marginBottom: "20px" }}>
            <Paper elevation={3} style={{ padding: 12 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h2>Categorii:</h2>
                <Button
                  variant="contained"
                  onClick={() => handleNomenclatorClick("categories")}
                  style={{ background: "#00054e" }}
                >
                  Adaugă categorie
                </Button>
              </div>
              <div className="desktop-grid-agro">
                <div>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th
                          style={{ paddingRight: "30px", paddingLeft: "30px" }}
                        >
                          Activat
                        </th>
                        <th style={{ paddingRight: "30px" }}>Label</th>
                        <th style={{ paddingRight: "30px" }}>Valoare</th>

                        <th style={{ paddingRight: "30px" }}>Acțiune</th>
                      </tr>
                    </thead>
                    <tbody>
                      {licenseData?.nomenclatoare?.categories?.map(
                        (item, index) => (
                          <tr key={index}>
                            <td>{item?.enabled}</td>
                            <td>{item?.label}</td>
                            <td>{item?.value}</td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "5px",
                                  paddingRight: "10px",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  onClick={() =>
                                    handleEditModalOpen(item, "categories")
                                  }
                                  style={{
                                    marginLeft: "10px",
                                    background: "#00054e",
                                  }}
                                >
                                  Edit
                                </Button>
                                <Button
                                  variant="contained"
                                  onClick={() =>
                                    deleteNomenclatoare(item.id, "categories")
                                  }
                                  style={{
                                    marginLeft: "10px",
                                    background: "#FF6000",
                                  }}
                                >
                                  Șterge
                                </Button>
                              </div>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </Paper>
          </div>
        </div>
        {/* </div> */}
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        <Paper elevation={3}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              padding: 8,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                marginBottom: "8px",
              }}
            >
              <h2>Tranzacții:</h2>
              <Button
                variant="contained"
                sx={{
                  bgcolor: "green.light", // normal background color
                  "&:hover": {
                    bgcolor: "green.dark", // hover background color
                  },
                }}
                // extrage excel-ul in fuctie de filtre
                // licenseData?.transactions
                onClick={() => generateExcelFile(filteredTransactions)}
              >
                Raport Excel
              </Button>
            </div>

            <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  renderInput={(params) => <TextField {...params} />}
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <Select
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">
                  <em>Mod Plată</em>
                </MenuItem>
                <MenuItem value="numerar">Numerar</MenuItem>
                <MenuItem value="card">Card</MenuItem>
              </Select>
              <Button
                variant="contained"
                sx={{
                  bgcolor: "red.light",
                  "&:hover": { bgcolor: "red.dark" },
                }}
                onClick={resetFilters}
              >
                Resetează Filtre
              </Button>
            </div>

            <table className="table table-striped">
              <thead>
                <tr>
                  <th style={{ paddingRight: "40px" }}>Valoare</th>
                  <th style={{ paddingRight: "40px" }}>Data</th>
                  <th style={{ paddingRight: "40px" }}>Mod plată</th>
                </tr>
              </thead>
              <tbody>
                {filteredTransactions.map((item, index) => (
                  <tr key={index} onClick={() => handleBonClick(item)}>
                    <td>{item?.totalValue} Ron</td>
                    <td>{item?.date}</td>
                    <td>{item?.paymentType}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* <Button variant="contained" onClick={() => setOpenTransactionModal(true)}>
            Adauga Tranzactie (Test Button)
          </Button> */}
          </div>
        </Paper>
      </CustomTabPanel>

      <Modal
        open={openMaterial}
        onClose={handleModalMaterialClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
            <h2>Adaugă Produs Nou</h2>
            <br />
            <TextField
              type="text"
              placeholder="Nume"
              value={nume}
              onChange={(e) => setNume(e.target.value)}
            />
            <InputLabel id="demo-simple-select-label">UMS </InputLabel>
            <Select
              label="Categorie"
              value={umProdus}
              onChange={(e) => {
                setUmProdus(e.target.value);
              }}
            >
              {licenseData?.nomenclatoare?.ums?.map((item) => (
                <MenuItem key={item} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>

            <TextField
              type="text"
              placeholder="Pret"
              value={pret}
              onChange={(e) => setPret(e.target.value)}
            />

            <InputLabel id="demo-simple-select-label">TVA </InputLabel>
            <Select
              label="TVA"
              value={tva}
              onChange={(e) => {
                setTva(e.target.value);
              }}
            >
              {licenseData?.nomenclatoare?.tvas?.map((item) => (
                <MenuItem key={item} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <br />
            <br />
            <InputLabel id="demo-simple-select-label">Categorie </InputLabel>
            <Select
              label="Categorie"
              value={categorieProdus}
              onChange={(e) => setCategorieProdus(e.target.value)}
            >
              {uniqueCategories.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
              <MenuItem value={"adauga"}>Adaugă Categorie Noua</MenuItem>
            </Select>
            {categorieProdus === "adauga" && (
              <TextField
                type="text"
                placeholder="Categorie"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
              />
            )}
            <br />
            <br />
            <Button variant="contained" color="success" onClick={addProduct}>
              Adaugă Produs
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openTransactionModal}
        onClose={handleModalTransactionClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
            <h2>Adaugă Tranzactie Noua</h2>
            <br />
            <TextField
              type="text"
              placeholder="ID"
              value={transactionId}
              onChange={(e) => setTransactionId(e.target.value)}
            />
            <TextField
              type="text"
              placeholder="Pret"
              value={transactionPret}
              onChange={(e) => setTransactionPret(e.target.value)}
            />
            <TextField
              type="text"
              placeholder="Data"
              value={transactionData}
              onChange={(e) => setTransactionData(e.target.value)}
            />
            <TextField
              type="text"
              placeholder="Descriere"
              value={transactionDescriere}
              onChange={(e) => setTransactionDescriere(e.target.value)}
            />
            <br />
            <br />
            <Button
              variant="contained"
              color="success"
              onClick={addTransaction}
            >
              Adaugă Tranzactie
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
            <h2>Actualizeaza: {formEdit.name}</h2>
            <br />
            <TextField
              type="text"
              placeholder="Nume"
              value={formEdit.name}
              onChange={(e) => updateForm({ name: e.target.value })}
            />
            <TextField
              type="text"
              placeholder="Um"
              value={formEdit.um}
              onChange={(e) => updateForm({ um: e.target.value })}
            />

            <InputLabel id="demo-simple-select-label">UMS </InputLabel>
            <Select
              label="Categorie"
              value={formEdit.um}
              onChange={(e) => {
                updateForm({ um: e.target.value });
                setNewCategory("");
              }}
            >
              {licenseData?.nomenclatoare?.ums.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
              <MenuItem value={"adauga"}>Adaugă Categorie Noua</MenuItem>
            </Select>
            {formEdit.um === "adauga" && (
              <TextField
                type="text"
                placeholder="Categorie"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
              />
            )}
            <TextField
              type="text"
              placeholder="Pret"
              value={formEdit.price}
              onChange={(e) => updateForm({ price: e.target.value })}
            />
            <InputLabel id="demo-simple-select-label">TVA </InputLabel>
            <Select
              label="TVA"
              value={tva}
              onChange={(e) => {
                setTva(e.target.value);
              }}
            >
              {licenseData?.nomenclatoare?.tvas?.map((item) => (
                <MenuItem key={item} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <br />
            <br />
            <InputLabel id="demo-simple-select-label">Categorie </InputLabel>
            <Select
              label="Categorie"
              value={formEdit.category}
              onChange={(e) => {
                updateForm({ category: e.target.value });
                setNewCategory("");
              }}
            >
              {uniqueCategories.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
              <MenuItem value={"adauga"}>Adaugă Categorie Noua</MenuItem>
            </Select>
            {formEdit.category === "adauga" && (
              <TextField
                type="text"
                placeholder="Categorie"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
              />
            )}
            <br />
            <br />
            <Button
              variant="contained"
              color="success"
              onClick={updateMaterial}
            >
              Actualizeaza Item
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openBon}
        onClose={() => setOpenBon(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
            <span>Bon ID: {bonData?.id}</span>
            <span>Data: {bonData?.date}</span>
            <span>Tip Plata: {bonData?.paymentType}</span>
            <span>Total: {bonData?.totalValue} Ron</span>
            <br />
            <span>Produse:</span>
            {bonData?.produse?.map((item) => (
              <div>
                <li>Nume: {item.name}</li>
                <li>Categorie: {item.category}</li>
                <li>Pret: {item.price}</li>
                <li>TVA: {item.tva}</li>
                <hr />
              </div>
            ))}
          </div>
        </Box>
      </Modal>

      {/* Edit Modal */}
      <Modal open={editModalOpen} onClose={handleEditModalClose}>
        <Box sx={styleInv}>
          <h2>Edit Nomenclator</h2>
          <TextField
            margin="dense"
            label="Label"
            type="text"
            fullWidth
            value={editNomenclatorLabel}
            onChange={(e) => setEditNomenclatorLabel(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Value"
            type="text"
            fullWidth
            value={editNomenclatorValue}
            onChange={(e) => setEditNomenclatorValue(e.target.value)}
          />
          <InputLabel>Activat</InputLabel>
          <Select
            value={editNomenclatorEnabled}
            onChange={(e) => setEditNomenclatorEnabled(e.target.value)}
            fullWidth
          >
            <MenuItem value="Da">Da</MenuItem>
            <MenuItem value="Nu">Nu</MenuItem>
          </Select>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              onClick={handleEditModalClose}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                editNomenclator(currentType, currentNomenclator);
                handleEditModalClose();
              }}
              color="primary"
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal>

      {/* Add nomenclator modal */}
      <Modal
        open={openNomenclator}
        onClose={handleModalNomenclator}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div style={{ gap: "4px", display: "flex", flexDirection: "column" }}>
            <h2>Adaugă Nomenclator Nou: {selectedNomenclator}</h2>
            <br />
            {selectedNomenclator === "ums" && (
              <TextField
                type="text"
                placeholder="Prescurtare"
                value={nomenclatorId}
                onChange={(e) => setNomenclatorId(e.target.value)}
              />
            )}

            <TextField
              type="text"
              placeholder="Nume"
              value={nomenclatorLabel}
              onChange={(e) => setNomenclatorLabel(e.target.value)}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={nomenclatorEnabled}
                  onChange={(e) => setNomenclatorEnabled(e.target.checked)}
                />
              }
              label="Activat"
            />
            {selectedNomenclator === "tvas" && (
              <TextField
                type="text"
                placeholder="Index AMEF"
                value={indexAmef}
                onChange={(e) => setIndexAmef(e.target.value)}
              />
            )}

            {selectedNomenclator === "sgrs" && (
              <>
                <InputLabel id="demo-simple-select-label">TVA </InputLabel>
                <Select
                  label="TVA"
                  value={tva}
                  onChange={(e) => {
                    setTva(e.target.value);
                  }}
                >
                  {licenseData?.nomenclatoare?.tvas?.map((item) => (
                    <MenuItem key={item} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}

            <TextField
              type="text"
              placeholder="Valoare"
              value={nomenclatorValue}
              onChange={(e) => setNomenclatorValue(e.target.value)}
            />
            <br />
            <br />
            <Button
              variant="contained"
              color="success"
              onClick={() =>
                addNomenclator(`nomenclatoare.${selectedNomenclator}`)
              }
            >
              Adaugă Nomenclator
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default LicentaView;

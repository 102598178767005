import React, { useState, useEffect } from "react";
import "./SideMenu.css";
import { Link, useNavigate } from "react-router-dom";
import {
  ListItem,
  Collapse,
  Button,
  Modal,
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { useAuth } from "../Login/AuthContext";
import InfoIcon from "@mui/icons-material/Info";
import packageJson from "../../../../package.json";

import PosIcon from "../../../images/pos.png";
import { collection, getDoc, getDocs, doc } from "firebase/firestore";
import { db, auth } from "../../../utils/firebase/firebase";
import { useLicentaContext } from "../../ai_pos/context/licentaContext";
import { onAuthStateChanged } from "firebase/auth";
import { useCuiContext } from "../../ai_pos/context/cuiContext";
import { mainUrl } from "../../../utils/constants";
import { useLicentaContextGlobal } from "../../ai_pos/context/licentaContextGlobal";

const styleInv = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const styledSideMenu = {
  fontWeight: 300,
  fontSize: "19px",
  marginRight: "8px",
};

const styledSection = {
  fontWeight: 400,
  fontSize: "16px",
  color: "#D9DCE1",
};

const SideMenu = ({ setDarkMode, darkMode }) => {
  const material2 = {
    nume: "Alege Depozit",
  };
  const version = packageJson.version;
  const { licentaDataGlobal } = useLicentaContextGlobal();

  const { isAuthenticated, user } = useAuth();
  const { setLicentaData, licentaData } = useLicentaContext();
  const { cuis, setCuis } = useCuiContext();
  const navigate = useNavigate();

  const [licenses, setLicenses] = useState([]);

  const [santiers, setSantiers] = useState([]);
  const [depozits, setDepozits] = useState([]);

  const [santiersAgro, setSantiersAgro] = useState([]);
  const [depozitsAgro, setDepozitsAgro] = useState([]);

  const [nume, setNume] = useState();
  const [locatie, setLocatie] = useState();
  const [selectedDepozit, setSelectedDepozit] = useState(material2);

  const [open2, setOpen2] = useState(false);

  const [openSectionIndex, setOpenSectionIndex] = useState(null);
  const [selectedSubsectionIndex, setSelectedSubsectionIndex] = useState(null);
  const [trigger, setTrigger] = useState();

  const sectiuniArray = [
    {
      nume: "AI_POS",
      icon: PosIcon,
      sectiuni: [
        {
          nume: "Dashboard",
          link: "ai_pos/dashboard",
        },
        // {
        //   nume: "Licenta",
        //   data: [
        //     { nume: "Licenta 990-POS", _id: 0 },
        //     { nume: "Licenta AI POS 2", _id: 1 },
        //     { nume: "Licenta AI POS 3", _id: 2 },
        //     { nume: "Licenta AI POS 4", _id: 3 },
        //     { nume: "Licenta AI POS 5", _id: 4 },
        //   ],
        //   dropdown: true,
        //   nick: "Licenta",
        // },
        {
          nume: "Licențe",
          dropdown: true,
          nick: "Licenta",
        },
        // {
        //   nume: "Inventar",
        //   link: "ai_pos/inventar",
        //   // dropdown: false,
        //   // nick: "DeviceManagement",
        // },
      ],
    },
  ];

  const handleLicenseClick = async (license) => {
    setLicentaData(license);
    navigate(`/ai_pos/licenta/${license.serial}`);
  };

  // const toggleTheme = () => {
  //   setDarkMode(!darkMode);
  // };

  const handleDepozitChange = (event) => {
    setSelectedDepozit(event.target.value);
  };

  const handleModalAdauga = () => {
    setOpen2(false);
  };

  const toggleSection = (index) => {
    setOpenSectionIndex((prevIndex) => (prevIndex === index ? null : index));
    setSelectedSubsectionIndex(null);
  };

  const toggleSubsection = (index) => {
    setSelectedSubsectionIndex((prevIndex) =>
      prevIndex === index ? null : index
    );
  };

  return (
    <>
      <div className="side-menu-container">
        <div className="side-menu">
          <div>
            {isAuthenticated &&
              sectiuniArray?.map((section, sectionIndex) => (
                <div key={sectionIndex}>
                  <Collapse in={true}>
                    {section?.sectiuni?.map((subsection, subsectionIndex) => (
                      <div key={subsectionIndex} className="right-menu-item">
                        {subsection?.dropdown === true ? (
                          <ListItem
                            button
                            onClick={() => toggleSubsection(subsectionIndex)}
                          >
                            <span style={styledSideMenu}>
                              {subsection?.nume}
                            </span>
                            <svg
                              className="svgIconList"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                              <g
                                id="SVGRepo_tracerCarrier"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></g>
                              <g id="SVGRepo_iconCarrier">
                                {" "}
                                <path
                                  d="M20 7L4 7"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                ></path>{" "}
                                <path
                                  d="M15 12L4 12"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                ></path>{" "}
                                <path
                                  d="M9 17H4"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                ></path>{" "}
                              </g>
                            </svg>
                          </ListItem>
                        ) : (
                          <Link
                            className="nav-link"
                            to={
                              subsection?.dropdown === true
                                ? openSectionIndex === 1
                                  ? `${mainUrl}gestiune/${subsection?.link}`
                                  : `${mainUrl}agronomie/${subsection?.link}`
                                : `${mainUrl}${subsection?.link}`
                            }
                            onClick={() => setSelectedSubsectionIndex(null)}
                          >
                            {subsection?.nume}
                          </Link>
                        )}
                      </div>
                    ))}
                  </Collapse>
                </div>
              ))}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "8px",
              flexDirection: "column",
            }}
          >
            <span style={{ color: "#D9DCE1" }}>{`v${version}`}</span>
          </div>
        </div>
        {sectiuniArray[0]?.sectiuni[selectedSubsectionIndex]?.dropdown ===
          true && (
          <div className="right-side-menu">
            <div className="right-side-menu-box">
              {licentaDataGlobal?.length > 0 ? (
                licentaDataGlobal.map((item, itemIndex) => (
                  <div
                    className="nav-link-right"
                    key={itemIndex}
                    onClick={() => handleLicenseClick(item)}
                    style={{
                      cursor: "pointer",
                      margin: "10px 0",
                      backgroundColor:
                        licentaData?.serial == item.serial
                          ? "#1f257b"
                          : "#00054e",
                    }}
                  >
                    <span>{item.deviceName || item.serial}</span>
                  </div>
                ))
              ) : (
                <p>No licenses found</p>
              )}
            </div>
          </div>
        )}
      </div>

      <Modal
        open={open2}
        onClose={handleModalAdauga}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleInv}>
          <div>
            <h2>
              Adauga{" "}
              {
                sectiuniArray[openSectionIndex]?.sectiuni[
                  selectedSubsectionIndex
                ]?.nick
              }
            </h2>
            <br />
            <TextField
              type="text"
              placeholder={`Nume ${sectiuniArray[openSectionIndex]?.sectiuni[selectedSubsectionIndex]?.nick}`}
              onChange={(e) => setNume(e.target.value)}
            ></TextField>
            <br />
            <br />
            <TextField
              type="text"
              placeholder={`Locatie ${sectiuniArray[openSectionIndex]?.sectiuni[selectedSubsectionIndex]?.nick}`}
              onChange={(e) => setLocatie(e.target.value)}
            ></TextField>
            <br />
            <br />
            <Button
              variant="contained"
              sx={{
                bgcolor: "blue.focus",
                "&:hover": {
                  bgcolor: "blue.focusHover",
                },
              }}
            >
              Adauga{" "}
              {
                sectiuniArray[openSectionIndex]?.sectiuni[
                  selectedSubsectionIndex
                ]?.nick
              }
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default SideMenu;

import React, { useState } from "react";
import { TextField, Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { getAuth, confirmPasswordReset } from "firebase/auth";

const buttonStyle = {
  fontWeight: 700,
  marginTop: "16px",
  width: "20%",
};

function PasswordReset() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { oobCode } = useParams(); // Assuming the oobCode is passed as a URL parameter

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    const auth = getAuth();

    try {
      await confirmPasswordReset(auth, oobCode, password);
      setMessage("Password has been reset successfully.");
      setError("");
      // Redirect to login page after a successful password reset
      setTimeout(() => {
        navigate("/login");
      }, 3000); // Redirect after 3 seconds
    } catch (error) {
      setError(error.message);
      setMessage("");
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", gap: 20, textAlign: "center", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
      <Typography variant="h5">Resetare Parolă</Typography>
      <TextField
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Parolă Nouă"
        required
        style={{ width: "30%", marginTop: "10px"}}
      />
      <TextField
        type="password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        placeholder="Confirmă Parola"
        required
        style={{ width: "30%", marginTop: "10px"}}
      />
      <Button variant="contained" color="primary" style={buttonStyle} type="submit">
        Resetează Parola
      </Button>
      {message && <p>{message}</p>}
      {error && <p>{error}</p>}
    </form>
  );
}

export default PasswordReset;

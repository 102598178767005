import React, { useState } from "react";
import { TextField, Button, Typography } from "@mui/material";
import md5 from "md5";
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";
// import sma from "../../../images/smaTitle.png";
import MySvgIcon2 from "../../../images/AiPosV2";
import "./LoginPage.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import SignUpForm from "../../auth/SignUp";

const headerStyle = {
  fontSize: "40px",
  fontWeight: 500,
};

const textFieldStyle = {
  marginBottom: "16px",
  width: "100%",
};

const buttonStyle = {
  fontWeight: 700,
  marginTop: "16px",
};

const Login = () => {
  const [username, setUsername] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPass, setConfPass] = useState("");
  const [verified, setVerified] = useState(true);
  const [error, setError] = useState("");
  const { setIsAuthenticated, setUserData } = useAuth();
  const navigate = useNavigate();

  const [role, setRole] = React.useState("standard");

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const currentDate = new Date();

  const sevenDaysLater = new Date(currentDate);
  const expireDate = sevenDaysLater.setDate(currentDate.getDate() + 7);

  const futureDate = new Date(currentDate.getTime() + 10 * 1000);

  const handleEmailChange = (event) => {
    const trimmedEmail = event.target.value.trim();
    setEmail(trimmedEmail);
  };

  const validateEmail = (email) => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    return emailRegex.test(email);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfPassword = (event) => {
    setConfPass(event.target.value);
    if (password === confPass) {
      setVerified(false);
    } else {
      setVerified(true);
    }
  };

  const handleRoleText = () => {
    switch (role) {
      case "standard":
        return "Only read rights.";
      case "moderator":
        return "Read and write rights.";
      case "admin":
        return "Full rights. (read, write, delete, add)";
      // Add more cases if needed
      default:
        return ""; // Default case, return an empty string or another default value
    }
  };

  // const handleLoginClick = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const encryptedPassword = md5(password);

  //     const response = await fetch("http://localhost:5050/api/users/auth", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       credentials: "include",
  //       body: JSON.stringify({
  //         name: username,
  //         email,
  //         password: encryptedPassword,
  //       }),
  //     });

  //     if (!response.ok) {
  //       const error = await response.json();
  //       throw new Error(error.message);
  //     }

  //     const loginData = await response.json();

  //     localStorage.setItem("expireDate", expireDate);
  //     localStorage.setItem("login", true);

  //     setUserData(loginData);
  //     setIsAuthenticated(true);
  //     navigate("/");
  //   } catch (error) {
  //     setError(error.message);
  //     console.log(error);
  //   }
  // };

  const handleSignUpClick = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      alert("Invalid email address");
      return;
    } else if (!password.trim()) {
      {
        alert("Please enter a password");
        return;
      }
    } else {
      try {
        const encryptedPassword = md5(password);

        const response = await fetch("http://localhost:5050/api/users", {
          credentials: "include",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: username,
            email,
            password: encryptedPassword,
            role,
            isAdmin: role === "admin",
          }),
        });

        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.message);
        }
        navigate("/login");
      } catch (error) {
        setError(error.message);
        console.log(error);
      }
    }
  };

  return (
    <div
      style={{
        marginTop: "3%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div className="login-screen">
        <Typography style={headerStyle}>Bine ați venit la</Typography>
        <MySvgIcon2 />
        <br />
        <br />
        
        <div style={{ display: "flex", alignItems: "center", textAlign: "center"}}>
        <Typography style={{ fontSize: "17px", }}>
            Suntem încântați să vă avem alături!
            <br />
            Descoperiți aplicația <b>AIPOS</b> și bucurați-vă de toate funcționalitățile ei inovatoare.
          </Typography>
          </div>
        <br />
        {error && <div className="error">{error}</div>}

        <SignUpForm />
      </div>
    </div>
  );
};

export default Login;

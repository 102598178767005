import React, { useState, useEffect } from "react";
import "./device.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";

function DeviceManagement() {
  const dummyDevices = [
    {
      deviceType: "POS",
      serialNumber: "SN123456",
      judet: "Suceava",
      locatie: "Mihai Eminescu 23",
      status: "Active",
    },
    {
      deviceType: "Self-Checkout",
      serialNumber: "SN654321",
      judet: "Ilfov",
      locatie: "Aisle 3",
      status: "Maintenance",
    },
    {
      deviceType: "Self-Checkout",
      serialNumber: "SN654321",
      judet: "Timis",
      locatie: "Buftea 34",
      status: "Requested",
    },
    {
      deviceType: "POS",
      serialNumber: "SN654351",
      judet: "Arad",
      locatie: "Dorobanti 54",
      status: "Transit",
    },
    // ... more dummy devices ...
  ];

  const [devices, setDevices] = useState([]);

  // Action useState
  const [showAddDeviceForm, setShowAddDeviceForm] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  // FILTERS
  const [filterValues, setFilterValues] = useState({
    judet: "",
    type: "",
    status: "",
  });

  const resetFilters = () => {
    setFilterValues({
      judet: "",
      type: "",
      status: "",
    });
    setDevices(dummyDevices); // Reset the displayed devices to the initial list
  };

  const toggleAddDeviceForm = () => {
    setShowAddDeviceForm(!showAddDeviceForm);
  };

  useEffect(() => {
    // simulate fetch
    setDevices(dummyDevices);
  }, []);

  const handleAddDevice = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const newDevice = Object.fromEntries(formData);
    // Replace with API call to add device
    setDevices((prevDevices) => [...prevDevices, newDevice]);
    toggleAddDeviceForm();
  };

  const applyFilters = () => {
    // Filter the devices based on the selected filter values
    const filteredDevices = dummyDevices.filter((device) => {
      // Check if the device matches the filter criteria
      return (
        (!filterValues.judet ||
          device.judet
            .toLowerCase()
            .includes(filterValues.judet.toLowerCase())) &&
        (!filterValues.type ||
          device.deviceType
            .toLowerCase()
            .includes(filterValues.type.toLowerCase())) &&
        (!filterValues.status ||
          device.status
            .toLowerCase()
            .includes(filterValues.status.toLowerCase()))
      );
    });

    // Update the devices state with the filtered devices
    setDevices(filteredDevices);

    // Close the filter inputs section
    setShowFilters(true);
  };

  return (
    <div className="p-4">
      <h2 className="text-xl md:text-2xl font-bold mb-4">
        Inventar Device-uri
      </h2>

      <div className="device-and-filters-container">
        {/* Button to toggle form */}
        <button
          onClick={toggleAddDeviceForm}
          className={`custom-btn mb-4 ${
            showAddDeviceForm ? "custom-btn-red" : "custom-btn-blue"
          }`}
        >
          {showAddDeviceForm ? (
            <>
              <FontAwesomeIcon icon={faTimes} /> Close
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faPlus} /> Add Device
            </>
          )}
        </button>

        {/* Button for filters to apply */}
        <button
          onClick={() => setShowFilters(!showFilters)}
          className={`custom-btn mb-4 ${
            showFilters ? "custom-btn-red-filter" : "custom-btn-filter"
          }`}
        >
          {showFilters ? (
            <>
              <FontAwesomeIcon icon={faTimes} /> Hide Filters
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faFilter} /> Filtreaza
            </>
          )}
        </button>
      </div>

      {showFilters && (
        <div className="filters mb-4">
          <input
            className="border p-3 w-full"
            name="judet"
            type="text"
            placeholder="Judet"
            value={filterValues.judet}
            onChange={(e) =>
              setFilterValues({ ...filterValues, judet: e.target.value })
            }
          />
          <input
            className="border p-3 w-full"
            name="type"
            type="text"
            placeholder="Type"
            value={filterValues.type}
            onChange={(e) =>
              setFilterValues({ ...filterValues, type: e.target.value })
            }
          />
          <input
            className="border p-3 w-full"
            name="status"
            type="text"
            placeholder="Status"
            value={filterValues.status}
            onChange={(e) =>
              setFilterValues({ ...filterValues, status: e.target.value })
            }
          />

          <div className="btns">
            <button
              onClick={applyFilters}
              className="custom-btn mb-4 custom-btn-apply-filters"
            >
              Apply Filters
            </button>

            <button
              onClick={resetFilters}
              className="custom-btn custom-btn-reset-filters"
            >
              Reset Filters
            </button>
          </div>
        </div>
      )}

      {/* Add Device Form */}
      {showAddDeviceForm && (
        <form onSubmit={handleAddDevice} className="mb-4">
          <div className="flex flex-col md:flex-row md:gap-4">
            <div className="mb-4 md:mb-0 md:flex-1">
              <input
                className="border p-3 w-full"
                name="deviceType"
                type="text"
                placeholder="Device Type"
                required
              />
            </div>
            <div className="mb-4 md:mb-0 md:flex-1">
              <input
                className="border p-3 w-full"
                name="serialNumber"
                type="text"
                placeholder="Serial Number"
                required
              />
            </div>
            <div className="mb-4 md:mb-0 md:flex-1">
              <input
                className="border p-3 w-full"
                name="judet"
                type="text"
                placeholder="Judet"
                required
              />
            </div>
            <div className="mb-4 md:mb-0 md:flex-1">
              <input
                className="border p-3 w-full"
                name="locatie"
                type="text"
                placeholder="Locatie"
                required
              />
            </div>
            <div className="mb-4 md:mb-0 md:flex-1">
              <input
                className="border p-3 w-full"
                name="status"
                type="text"
                placeholder="Status"
                required
              />
            </div>
          </div>
          <button
            type="submit"
            className="btn-inv text-white font-bold py-3 px-4 rounded w-full"
          >
            Adauga Device Nou
          </button>
        </form>
      )}

      {/* Device Table */}
      <div className="overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm md:text-base text-left text-gray-500">
          <thead className="text-xs md:text-sm text-gray-700 uppercase bg-gray-50">
            <tr>
              <th className="px-4 md:px-6 py-2">Model</th>
              <th className="px-4 md:px-6 py-2">Serial Number</th>
              <th className="px-4 md:px-6 py-2">Judet</th>
              <th className="px-4 md:px-6 py-2">Locatie</th>
              <th className="px-4 md:px-6 py-2">Status</th>
              {/* Add more headers if needed */}
            </tr>
          </thead>
          <tbody>
            {devices.map((device, index) => (
              <tr
                key={index}
                className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}
              >
                <td className="px-4 md:px-6 py-2 border-b border-gray-200">
                  {device.deviceType}
                </td>
                <td className="px-4 md:px-6 py-2 border-b border-gray-200">
                  {device.serialNumber}
                </td>
                <td className="px-4 md:px-6 py-2 border-b border-gray-200">
                  {device.judet}
                </td>
                <td className="px-4 md:px-6 py-2 border-b border-gray-200">
                  {device.locatie}
                </td>
                <td className="px-4 md:px-6 py-2 border-b border-gray-200">
                  {device.status}
                </td>
                {/* Add more data cells if needed */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DeviceManagement;

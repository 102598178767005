import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    alignItems: "flex-start",
    width: "100%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    width: "100%",
  },
  table: {
    flexDirection: "column",
    borderWidth: 1,
    borderColor: "#000",
    margin: 10,
    height: "auto",
  },
  headerRow: {
    flexDirection: "row",
    backgroundColor: "#7d7d7d",
  },
  row: {
    flexDirection: "row",
  },
  headerCell: {
    flex: 1,
    borderWidth: 1,
    borderColor: "#000",
    padding: 8,
    textAlign: "center",
    fontWeight: "bold",
  },
  cell: {
    flex: 1,
    borderWidth: 1,
    borderColor: "#000",
    padding: 8,
    textAlign: "center",
  },
  stripedRow: {
    flexDirection: "row",
    backgroundColor: "#f9f9f9", // Set the background color for striped rows
  },
});

const Contract = ({ materiale }) => {
  const tables = [];
  const itemsPerTable = 18;

  for (let i = 0; i < materiale.length; i += itemsPerTable) {
    const currentTableData = materiale.slice(i, i + itemsPerTable);
    const currentTable = (
      <View key={i} style={styles.table}>
        <View style={styles.headerRow}>
          <Text style={styles.headerCell}>Nume</Text>
          <Text style={styles.headerCell}>Model</Text>
          <Text style={styles.headerCell}>Categorie</Text>
          <Text style={styles.headerCell}>Stoc</Text>
        </View>
        {currentTableData.map((item, index) => (
          // Use stripedRow style for alternate rows
          <View
            key={index}
            style={index % 2 === 0 ? styles.row : styles.stripedRow}
          >
            <Text style={styles.cell}>{item.nume}</Text>
            <Text style={styles.cell}>{item.model}</Text>
            <Text style={styles.cell}>{item.categorie}</Text>
            <Text style={styles.cell}>{item.stoc}</Text>
          </View>
        ))}
      </View>
    );

    tables.push(currentTable);
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text> {` `}</Text>
          <Text>Tranzactii</Text>
          <br />
          <Text> {` `}</Text>
          <br />
          <Text>Produse: </Text>
          {tables}
        </View>
      </Page>
    </Document>
  );
};

export default Contract;

import { createContext, useContext, useState } from "react";

const ContextCui = createContext();

export const CuiProvider = ({ children }) => {
  const [cuis, setCuis] = useState([]);

  return (
    <ContextCui.Provider value={{ cuis, setCuis }}>
      {children}
    </ContextCui.Provider>
  );
};

export const useCuiContext = () => {
  return useContext(ContextCui);
};

import React, { useState, useEffect } from "react";
import { useAuth } from "../common/Login/AuthContext";
import { getFirestore, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { TextField, Button, Typography, Box, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useSnackbar } from "notistack";

const CompanyInfo = () => {
  const { userData } = useAuth();
  const [companyInfo, setCompanyInfo] = useState({
    name: "",
    address: "",
    phone: "",
    country: "",
    county: "",
    city: "",
    contactPerson: "",
    email: "",
    postalCode: ""
  });
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const db = getFirestore();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      if (userData) {
        const docRef = doc(db, "users", userData.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setCompanyInfo(docSnap.data().businessInfo || {});
        }
        setLoading(false);
      }
    };

    fetchCompanyInfo();
  }, [userData, db]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    if (userData) {
      const docRef = doc(db, "users", userData.uid);
      await setDoc(docRef, { businessInfo: companyInfo }, { merge: true });
      enqueueSnackbar("Informațiile companiei au fost salvate cu succes.", { variant: "success" });
      setEditing(false);
    }
  };

  const handleUpdate = async () => {
    if (userData) {
      const docRef = doc(db, "users", userData.uid);
      await updateDoc(docRef, { businessInfo: companyInfo });
      enqueueSnackbar("Datele companiei au fost updatate cu succes.", { variant: "success" });
      setEditing(false);
    }
  };

  const handleDelete = async () => {
    if (userData) {
      const docRef = doc(db, "users", userData.uid);
      await updateDoc(docRef, { businessInfo: {} });
      setCompanyInfo({
        name: "",
        address: "",
        phone: "",
        country: "",
        county: "",
        city: "",
        contactPerson: "",
        email: "",
        postalCode: ""
      });
      enqueueSnackbar("Datele companiei au fost șterse cu succes.", { variant: "success" });
      setEditing(true);
    }
    setConfirmDeleteOpen(false);
  };

  const renderCompanyInfo = () => (
    <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
      <Typography variant="h5" style={{ fontWeight: "bold", marginBottom: "20px" }}>
        Informațiile Companiei
      </Typography>
      <Typography variant="body1" style={{ marginBottom: "10px" }}>
        <strong>Numele Companiei:</strong> {companyInfo.name}
      </Typography>
      <Typography variant="body1" style={{ marginBottom: "10px" }}>
        <strong>Adresa:</strong> {companyInfo.address}
      </Typography>
      <Typography variant="body1" style={{ marginBottom: "10px" }}>
        <strong>Telefon:</strong> {companyInfo.phone}
      </Typography>
      <Typography variant="body1" style={{ marginBottom: "10px" }}>
        <strong>Țara:</strong> {companyInfo.country}
      </Typography>
      <Typography variant="body1" style={{ marginBottom: "10px" }}>
        <strong>Județ:</strong> {companyInfo.county}
      </Typography>
      <Typography variant="body1" style={{ marginBottom: "10px" }}>
        <strong>Localitate:</strong> {companyInfo.city}
      </Typography>
      <Typography variant="body1" style={{ marginBottom: "10px" }}>
        <strong>Nume Persoană:</strong> {companyInfo.contactPerson}
      </Typography>
      <Typography variant="body1" style={{ marginBottom: "10px" }}>
        <strong>Email:</strong> {companyInfo.email}
      </Typography>
      <Typography variant="body1" style={{ marginBottom: "10px" }}>
        <strong>Cod Poștal:</strong> {companyInfo.postalCode}
      </Typography>
      <Box style={{ display: "flex", justifyContent: "flex-start", gap: "10px", marginTop: "20px" }}>
        <Button variant="contained" style={{ background: "#00054e"}} onClick={() => setEditing(true)}>
          Edit
        </Button>
        <Button variant="contained" style={{ background: "#FF6000"}} onClick={() => setConfirmDeleteOpen(true)}>
          Șterge
        </Button>
      </Box>
    </Paper>
  );

  const renderEditForm = () => (
    <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
      <Typography variant="h5" style={{ fontWeight: "bold", marginBottom: "20px" }}>
        {companyInfo.name ? "Editează Informațiile Companiei" : "Adaugă Informațiile Companiei"}
      </Typography>
      <TextField
        label="Numele Companiei"
        name="name"
        value={companyInfo.name}
        onChange={handleChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <TextField
        label="Adresă"
        name="address"
        value={companyInfo.address}
        onChange={handleChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <TextField
        label="Telefon"
        name="phone"
        value={companyInfo.phone}
        onChange={handleChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <TextField
        label="Țara"
        name="country"
        value={companyInfo.country}
        onChange={handleChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <TextField
        label="Județ"
        name="county"
        value={companyInfo.county}
        onChange={handleChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <TextField
        label="Localitate"
        name="city"
        value={companyInfo.city}
        onChange={handleChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <TextField
        label="Nume Persoana"
        name="contactPerson"
        value={companyInfo.contactPerson}
        onChange={handleChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <TextField
        label="Email"
        name="email"
        value={companyInfo.email}
        onChange={handleChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <TextField
        label="Cod Postal"
        name="postalCode"
        value={companyInfo.postalCode}
        onChange={handleChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <Box style={{ display: "flex", justifyContent: "flex-start", gap: "10px", marginTop: "20px" }}>
        <Button variant="contained" style={{ background: "#00054e"}} onClick={handleUpdate}>
          Update
        </Button>
        <Button variant="contained" style={{ background: "#FF6000"}} onClick={() => setEditing(false)}>
          Cancel
        </Button>
      </Box>
    </Paper>
  );

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div style={{ maxWidth: "600px", margin: "20px 40px", textAlign: "left" }}>
      {editing || !companyInfo.name ? renderEditForm() : renderCompanyInfo()}

      <Dialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
      >
        <DialogTitle>Confirmare Ștergere</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sunteti sigur ca vreti sa stergeti datele dumneavoastra?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error">
            Continua
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CompanyInfo;

import React, { useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Navbar from "./components/common/navbar";

import SideMenu from "./components/common/SideMenu/SideMenu";
import SideMenuMobile from "./components/common/SideMenuMobile/SideMenuMobile";
import HomePage from "./components/common/HomePage";

import { useAuth } from "./components/common/Login/AuthContext";
import Login from "./components/common/Login/LoginPage";
import { useSideMenuContext } from "./components/common/SideMenuMobile/context/AuthContext";
import "./App.css";
import Signup from "./components/common/Login/SignupPage";

import { ThemeProvider } from "@mui/material/styles";
import { lightTheme, darkTheme } from "./utils/theme/theme"; // Adjust the path as necessary

import AccountController from "./components/common/Account/AccountController";
import UserManagementContainer from "./components/common/UserManagemnt/UserManagementContainer";

import AiPosContainer from "./components/ai_pos/aiPosContainer";
import LicentaView from "./components/ai_pos/LicentaView";
import DeviceManagement from "./components/ai_pos/inventar/deviceManagement";
import ForgotPasswordForm from "./components/auth/ForgotPasswordForm";
import PasswordReset from "./components/auth/PasswordComponent";
import CompanyInfo from "./components/ai_pos/BusinessInfo";

function PrivateRoute({ children }) {
  const { isAuthenticated } = useAuth();
  // return isAuthenticated ? <>{children}</> : <Navigate to="/login" />;
  return isAuthenticated ? <>{children}</> : <Navigate to="/login" />;
}

const App = () => {
  const { mobileMenuOn } = useSideMenuContext();

  const [darkMode, setDarkMode] = useState(false); // State to toggle between themes

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          backgroundColor: "#00054e",
        }}
      >
        <Navbar />
        <div className="mobile">{mobileMenuOn && <SideMenuMobile />}</div>

        <div style={{ display: "flex", flex: 1 }}>
          <div className="desktop">
            <SideMenu setDarkMode={setDarkMode} darkMode={darkMode} />
          </div>

          <div
            style={{
              flex: 1,
              padding: 20,
              borderRadius: "12px 0px",
              backgroundColor: "white",
              overflowY: "auto",
              boxShadow: "inset 0px 0px 10px 5px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/forgot-password" element={<ForgotPasswordForm />} />
              <Route
                path="/reset-password/:oobCode"
                element={<PasswordReset />}
              />

              <Route
                path="/user-management"
                element={
                  <PrivateRoute>
                    <UserManagementContainer />
                  </PrivateRoute>
                }
              />

              <Route
                path="/company-info"
                element={
                  <PrivateRoute>
                    <CompanyInfo />
                  </PrivateRoute>
                }
              />

              <Route
                path="/user-config"
                element={
                  <PrivateRoute>
                    <AccountController />
                  </PrivateRoute>
                }
              />

              {/* <Route path="/register" element={<RegisterPage />} /> */}

              <Route exact path="/" element={<HomePage />} />

              <Route
                exact
                path="/ai_pos/dashboard"
                element={
                  <PrivateRoute>
                    <AiPosContainer />
                  </PrivateRoute>
                }
              />

              <Route
                exact
                path="/ai_pos/licenta/:license"
                element={
                  <PrivateRoute>
                    <LicentaView />
                  </PrivateRoute>
                }
              />

              <Route
                exact
                path="/ai_pos/inventar"
                element={
                  <PrivateRoute>
                    <DeviceManagement />
                  </PrivateRoute>
                }
              />
            </Routes>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default App;

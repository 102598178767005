import { createContext, useContext, useEffect, useState } from "react";

const ContextLicenta = createContext();

export const LicentaProvider = ({ children }) => {
  const [licentaData, setLicentaData] = useState();

  return (
    <ContextLicenta.Provider value={{ licentaData, setLicentaData }}>
      {children}
    </ContextLicenta.Provider>
  );
};

export const useLicentaContext = () => {
  return useContext(ContextLicenta);
};

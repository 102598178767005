import { createContext, useContext, useEffect, useState } from "react";

const ContextLicenta = createContext();

export const LicentaDataProvider = ({ children }) => {
  const [licentaDataVal, setLicentaDataVal] = useState();

  return (
    <ContextLicenta.Provider value={{ licentaDataVal, setLicentaDataVal }}>
      {children}
    </ContextLicenta.Provider>
  );
};

export const useLicentaDataContext = () => {
  return useContext(ContextLicenta);
};

import React, { useState } from "react";
import { TextField, Button, Typography } from "@mui/material";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";

const buttonStyle = {
  fontWeight: 700,
  marginTop: "16px",
  width: "20%"
};

function ForgotPasswordForm() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const auth = getAuth();
    const db = getFirestore(); 

    try {
      const userQuery = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(userQuery);

      if (querySnapshot.empty) {
        setError("Email-ul nu există în baza de date.");
        return;
      }

      await sendPasswordResetEmail(auth, email);
      setMessage("Email-ul pentru resetarea parolei a fost trimis.");
      setError("");
    } catch (error) {
      setError(error.message);
      setMessage("");
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", gap: 20, textAlign: "center", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
      <Typography variant="h5">Resetare Parolă</Typography>
      <TextField
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
        required
        style={{ width: "30%", marginTop: "10px"}}      
      />
      <Button variant="contained" color="primary" style={buttonStyle} type="submit">
        Trimite Email de Resetare
      </Button>
      {message && <p>{message}</p>}
      {error && <p>{error}</p>}
    </form>
  );
}

export default ForgotPasswordForm;

import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { NavLink } from "react-router-dom";
// import { ReactComponent as Sma } from "../../images/sma.svg";
import MySvgIcon from "../../images/AiPos";
import { useUserContext } from "../ai_pos/context/userContext";
import { useAuth } from "./Login/AuthContext";
import { useNavigate } from "react-router-dom";
import { useSideMenuContext } from "./SideMenuMobile/context/AuthContext";
import MenuIcon from "@mui/icons-material/Menu";

import aiPosLogo from "../../images/aiPosLogo.png";

import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function Navbar() {
  const { setIsAuthenticated, isAuthenticated, userData, setUserData } =
    useAuth();
  const { user } = useUserContext();
  const navigate = useNavigate();

  const { mobileMenuOn, setMobileMenuOn } = useSideMenuContext();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleAddUser = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);

    navigate("/signup");
  };

  const handleAccount = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);

    navigate("/user-config");
  };

  const handleUserManagement = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);

    navigate("/user-management");
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleLogOut = async (event) => {
    try {
      // const response = await fetch("http://localhost:5050/api/users/logout", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // });

      // if (!response.ok) {
      //   const error = await response.json();
      //   throw new Error(error.message);
      // }
      localStorage.setItem("login", false);
      setUserData({});

      setIsAuthenticated(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }

    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);

    window.location.reload();
  };
  // console.log(userData);

  const toggleMenu = () => {
    // setMenuOpen(!isMenuOpen);
    setMobileMenuOn(!mobileMenuOn);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
        height: "75px",
        background: "linear-gradient(to top, #00054e, #000031)",
        // background: "linear-gradient(90deg, rgba(0,5,78,1) 0%, rgba(0,0,49,1) 100%)",
        zIndex: 99,
      }}
    >
      <NavLink className="navbar-brand" to="ai_pos/dashboard">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginTop: "6px",
              marginLeft: "20px",
            }}
          ></div>
          {/* <img src={sma} alt="sma" width="230px" /> */}
          {/* <img src={Sma} alt="My Icon" /> */}
          <img src={aiPosLogo} alt="aiPos" width="110px" />
        </div>
      </NavLink>

      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="mobile">
          <div style={{ marginRight: "12px" }}>
            <MenuIcon onClick={toggleMenu} fontSize="large" color="primary" />
          </div>
        </div>

        <div className="desktop">
          {isAuthenticated ? (
            <div>
              <div>
                <Button
                  ref={anchorRef}
                  id="composition-button"
                  aria-controls={open ? "composition-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  sx={{ color: "black" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      padding: "5px 5px 5px 12px",
                      border: "1px solid black",
                      borderRadius: "12px",
                      backgroundColor: "white",
                      marginRight: "14px",
                    }}
                  >
                    {/* {" "} */}
                    {/* <span>{userData?.name}</span> */}

                    {user && (
                      <span style={{ marginRight: "10px" }}>{user.name}</span>
                    )}
                    <KeyboardArrowDownIcon />
                  </div>
                </Button>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom"
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom" ? "center top" : "left bottom",
                        width: "150px",
                      }}
                    >
                       <Paper style={{marginRight: "5px"}}>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            key={12}
                            autoFocusItem={open}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                            > 
                            <MenuItem onClick={() => navigate("/company-info")} key={13}>
                              Profil
                            </MenuItem>
                            {/* <MenuItem onClick={handleAccount}>Account</MenuItem> */}
                            {userData?.isAdmin && (
                              <MenuItem onClick={handleAddUser}>
                                Add user
                              </MenuItem>
                            )}
                            {userData?.isAdmin && (
                              <MenuItem onClick={handleUserManagement}>
                                Manage users
                              </MenuItem>
                            )}

                            <MenuItem
                            onClick={handleLogOut}
                              style={{
                                transition: "background-color 0.5s ease, color 0.5s ease"
                              }}
                              onMouseEnter={(e) => {
                                e.target.style.backgroundColor = 'red';
                                e.target.style.color = 'white';
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.backgroundColor = '';
                                e.target.style.color = '';
                              }}
                            >Deconectare</MenuItem>
                            {/* <MenuItem
                              type="button"
                              className="btn btn-danger"
                              onClick={handleLogOut}
                            >
                              {" "}
                              Log Out
                            </MenuItem> */}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </div>
          ) : (
            <button
              type="button"
              className="btn btn-success"
              onClick={() => navigate("/login")}
              style={{ marginRight: "30px" }}
            >
              {" "}
              Autentificare
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

import React from "react";
import AiPosView from "./aiPosView";

export const licenteArray = [
  {
    nume: "Licenta 990-POS",
    nomenclatoare: [
      { nume: "Zuzu", categorie: "Lapte", um: "1L", tva: 9, pret: 12 },
      { nume: "Aqua Carpatica", categorie: "Apa", um: "1L", tva: 9, pret: 4 },
      {
        nume: "Paine Bucuria",
        categorie: "Panificatie",
        um: "500g",
        tva: 5,
        pret: 3,
      },
      {
        nume: "Mere Golden",
        categorie: "Fructe",
        um: "1kg",
        tva: 9,
        pret: 5,
      },
      {
        nume: "Faina Alba",
        categorie: "Bacanie",
        um: "1kg",
        tva: 9,
        pret: 2.5,
      },
      {
        nume: "Oua de tara",
        categorie: "Oua",
        um: "buc",
        tva: 5,
        pret: 0.5,
        cantitate: 10,
      },
      {
        nume: "Unt Ardealul",
        categorie: "Lactate",
        um: "200g",
        tva: 9,
        pret: 6,
      },
      {
        nume: "Cascaval Dobrogea",
        categorie: "Lactate",
        um: "200g",
        tva: 9,
        pret: 8,
      },
      {
        nume: "Cafea Lavazza",
        categorie: "Cafea",
        um: "250g",
        tva: 9,
        pret: 15,
      },
      {
        nume: "Ciocolata Milka",
        categorie: "Dulciuri",
        um: "100g",
        tva: 9,
        pret: 4,
      },
    ],
    vanzari: [
      { date: "2024-01-22", value: 120 },
      { date: "2024-01-23", value: 263 },
      { date: "2024-01-24", value: 150 },
      { date: "2024-01-25", value: 221 },
      { date: "2024-01-26", value: 140 },
      { date: "2024-01-27", value: 22 },
      { date: "2024-01-28", value: 145 },
      { date: "2024-01-29", value: 155 },
      { date: "2024-01-30", value: 21 },
      { date: "2024-01-31", value: 110 },
    ],
    id: "990-POS",
  },
  {
    nume: "Licenta 372-POS",
    vanzari: [
      { date: "2024-01-22", value: 120 },
      { date: "2024-01-23", value: 35 },
      { date: "2024-01-24", value: 150 },
      { date: "2024-01-25", value: 160 },
      { date: "2024-01-26", value: 14 },
      { date: "2024-01-27", value: 130 },
      { date: "2024-01-28", value: 1445 },
      { date: "2024-01-29", value: 15 },
      { date: "2024-01-30", value: 125 },
      { date: "2024-01-31", value: 110 },
    ],
    id: "372-POS",
  },
  {
    nume: "Licenta AI POS 3",
    vanzari: [
      { date: "2024-01-22", value: 120 },
      { date: "2024-01-23", value: 135 },
      { date: "2024-01-24", value: 10 },
      { date: "2024-01-25", value: 160 },
      { date: "2024-01-26", value: 14 },
      { date: "2024-01-27", value: 130 },
      { date: "2024-01-28", value: 142 },
      { date: "2024-01-29", value: 155 },
      { date: "2024-01-30", value: 125 },
      { date: "2024-01-31", value: 10 },
    ],
    id: "2",
  },
  {
    nume: "Licenta AI POS 4",
    vanzari: [
      { date: "2024-01-22", value: 120 },
      { date: "2024-01-23", value: 135 },
      { date: "2024-01-24", value: 150 },
      { date: "2024-01-25", value: 16 },
      { date: "2024-01-26", value: 140 },
      { date: "2024-01-27", value: 13 },
      { date: "2024-01-28", value: 145 },
      { date: "2024-01-29", value: 155 },
      { date: "2024-01-30", value: 1 },
      { date: "2024-01-31", value: 1104 },
    ],
  },
  {
    nume: "Licenta AI POS 5",
    vanzari: [
      { date: "2024-01-22", value: 120 },
      { date: "2024-01-23", value: 135 },
      { date: "2024-01-24", value: 10 },
      { date: "2024-01-25", value: 60 },
      { date: "2024-01-26", value: 140 },
      { date: "2024-01-27", value: 30 },
      { date: "2024-01-28", value: 145 },
      { date: "2024-01-29", value: 155 },
      { date: "2024-01-30", value: 15 },
      { date: "2024-01-31", value: 110 },
    ],
  },
  {
    nume: "Licenta AI POS 6",
    vanzari: [
      { date: "2024-01-22", value: 120 },
      { date: "2024-01-23", value: 135 },
      { date: "2024-01-24", value: 150 },
      { date: "2024-01-25", value: 160 },
      { date: "2024-01-26", value: 140 },
      { date: "2024-01-27", value: 130 },
      { date: "2024-01-28", value: 145 },
      { date: "2024-01-29", value: 155 },
      { date: "2024-01-30", value: 125 },
      { date: "2024-01-31", value: 110 },
    ],
  },

  {
    nume: "Licenta AI POS 7",
    vanzari: [
      { date: "2024-01-22", value: 120 },
      { date: "2024-01-23", value: 135 },
      { date: "2024-01-24", value: 150 },
      { date: "2024-01-25", value: 160 },
      { date: "2024-01-26", value: 140 },
      { date: "2024-01-27", value: 130 },
      { date: "2024-01-28", value: 145 },
      { date: "2024-01-29", value: 155 },
      { date: "2024-01-30", value: 125 },
      { date: "2024-01-31", value: 110 },
    ],
  },
];
const AiPosContainer = () => {
  return <AiPosView licenteArray={licenteArray} />;
};

export default AiPosContainer;
